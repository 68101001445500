import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap'; // If using reactstrap for buttons
import GetInTouch from "components/HomeComponents/GetInTouch";
import { FaHome } from "react-icons/fa"; // Import FontAwesome Home Icon


const ThankYouPage = () => {
  const location = useLocation();
  const { requestNumber } = location.state || {};
  const navigate = useNavigate();

  // Extract the last part of the URL (for example, 'health-screening')
  const currentPage = location.pathname.split("/").filter(Boolean).pop();

  // Capitalize the page name
  const formattedPageName = currentPage
    ? currentPage.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
    : "Home";

  return (
    <>
     {/* <div
            style={{
            backgroundColor: "rgb(135, 206, 235)",
            height: "25vh",
            position: "fixed",
            top: 0,
            width: "100%",
            opacity: 0.5,
            zIndex: 999,
            marginTop: '6%',
            }}
        >
        <div className="container d-flex align-items-center justify-content-start">
          <span style={{ fontSize: "1.2rem", color: "#000", marginTop: '-3%', }}>
       
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              <FaHome style={{ marginRight: "5px" }} /> 
              Home
            </a>
            &nbsp; &gt; &nbsp;
            {formattedPageName} 
          </span>
        </div>
      </div> */}
      
    <div style={styles.container}>
         

      <div style={styles.border}>
      <div style={styles.card}>
        <h1 style={styles.title}>Thank You!</h1>
        {/* <p style={styles.subtitle}>for your request</p> */}
        <p style={styles.subtitle}>for your request</p>
        {/* <p style={styles.requestNumber}>
          Your request no. is <strong>{requestNumber || 'XXXXXX'}</strong>
        </p> */}
        {/* <p style={styles.note}>
          Please note your request no. for future communication.
        </p> */}
        <p style={styles.note}>
          Please comes agin and showing trust in us again.

.
        </p>

        <Button 
          className="addNewBtnStyle"
          color="primary" 
          style={styles.button} 
          onClick={() => navigate('/new-request')}>
          New Request
        </Button>
        {/* <Button className="addNewBtnStyle" color="primary" size="sm" block style={styles.button} 
            onClick={() => navigate('/Request')}>
            New Request
        </Button> */}
      </div>
      </div>
      <GetInTouch />
    </div>
    </>
  );
};



// Inline Styles to match the provided UI
const styles = {
  container: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    backgroundColor: '#f4f7fa', // Soft blue background similar to the image
    marginTop: '8%',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '40px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for card effect
    // maxWidth: '600px',
    width: '100%',
    // border: '1px solid black',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#333',
  },
  subtitle: {
    fontSize: '1.25rem',
    color: '#666',
    marginTop: '10px',
  },
  requestNumber: {
    fontSize: '1.2rem',
    color: '#000',
    margin: '20px 0',
  },
  note: {
    fontSize: '1rem',
    color: '#888',
    marginBottom: '30px',
  },
  button: {
    width: '200px',
    display: 'block',
    margin: '0 auto',
  },
};

export default ThankYouPage;






// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// const ThankYouPage = () => {
//   const location = useLocation();
//   const { requestNumber, formValues, selectedServices } = location.state || {};
//   const navigate = useNavigate();

//   return (
//     <div className="thank-you-page">
//       <h1>Thank You!</h1>
//       <p>Your request no. is {requestNumber}</p>
//       <p>Please note your request number for future communication.</p>

//       <h2>Submitted Details</h2>
//       <ul>
//         {Object.keys(formValues).map((key) => (
//           <li key={key}><strong>{key}:</strong> {formValues[key]}</li>
//         ))}
//       </ul>

//       <h2>Selected Services</h2>
//       <ul>
//         {selectedServices.map((service) => (
//           <li key={service.id}>{service.sub_service_name}</li>
//         ))}
//       </ul>

//       <button onClick={() => navigate('/new-request')}>New Request</button>
//     </div>
//   );
// };

// export default ThankYouPage;
