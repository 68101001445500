/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

// import Index from "views/Index.js";
// MaviCare Pages
import Landing from "views/examples/Landing.js";
import Login from "./Login";
import Home from "views/MaviCarePages/HomePages/Home.js";
import AllCategory from "views/MaviCarePages/CategoryPages/AllCategory";
import Request from "views/MaviCarePages/RequestsPages/Request";
import ContactPage from "views/MaviCarePages/ContactForm/ContactPage";
import ThankYouPage from './components/RequestComponents/ThankYouPage';
import GetInTouchHeader from "components/HomeComponents/GetInTouchHeader";
import FeedbackPolicy from "views/MaviCarePages/Policy/FeedbackPolicy";
import TermsConditions from "views/MaviCarePages/Policy/TermsConditions";
import PrivacyPolicy from "views/MaviCarePages/Policy/PrivacyPolicy";
// import LoginHeader from "./components/Navbars/LoginHeader";






import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import ChairmanProfile from "views/MaviCarePages/ChairmanMsg";
import EventsDetails from "views/MaviCarePages/UpcomingEvents/EventDetails";
import PastDetails from "views/MaviCarePages/PastEvents/PastDetails";
// import UpcomingEvents from  'views/MaviCarePages/UpComingEvent';
import UpcomingEvents from "views/MaviCarePages/UpcomingEvents/UpcomingEvents";
import MemberRegForm from "views/MaviCarePages/UpcomingEvents/MemberRegForm";
import RecentPhotos from "views/MaviCarePages/RecentPhotos/RecentPhotos";
import OurGallery from "views/MaviCarePages/RecentPhotos/OurGallery";
import PastChairPersons from "views/MaviCarePages/PastChairPersons/PastChairPersons";
import Articals from "views/MaviCarePages/Articalspages/Articals";
import AddVacancy from "views/MaviCarePages/VacancyPage/AddVacancy";
import PastEvents from "views/MaviCarePages/PastEvents/PastEvents";
import WebsiteLayout from "Layouts/websiteLayout";
import NetworkingZone from "views/MaviCarePages/NetworkingZone/NetworkingZone";
import AddNetworkZone from "views/MaviCarePages/NetworkingZone/AddNetworkZone";
import ICAIRegistrationForm from "../src/views/ICAIRegistrationForm";
import NoticeDetails from "views/MaviCarePages/Noticedetails";
import PublicationNews from "views/MaviCarePages/PublicationNews/PublicationNews";
import { Container } from "react-bootstrap";
// import "react-responsive-pagination/themes/minimal.css";
import "react-responsive-pagination/themes/classic.css";
import OurResources from "views/MaviCarePages/OurResources";
import CalenderEvents from "views/MaviCarePages/CalenderEventsPages/CalenderEvents";
import DownloadResources from "views/MaviCarePages/DownloadResources.js";
// import StudyCircleDetail from "views/MaviCarePages/ViewStudyCircle";
import PublicationNewsletterDet from "views/MaviCarePages/PublicationNews/PublicationNewsletterDet";
import TransactionStatusForm from "views/MaviCarePages/TransactionStatus/TransactionForm";
// import OurGallery from './views/MaviCarePages/RecentPhotos/OurGallery';
import IcaiCricketLeague from "views/IcaiCricketLeague/IcaiCricketLeague";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
  <ScrollToTop />
    <Container fluid className="p-0 overflow-hidden">
      <WebsiteLayout>
        <Routes>
          {/* MaviCare */}
          {/* <Route path="/" exact element={<Home />} /> */}
          {/* <Route path="/" exact element={<Login />} /> */}
          <Route path="/" exact element={<AllCategory />} />
          <Route path="/profile-page" exact element={<Profile />} />
          <Route path="/landing-page" exact element={<Landing />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/home-page" exact element={<Home />} />
          <Route path="/AllCategory" exact element={<AllCategory />} />
          <Route path="/Contact-Us" exact element={<ContactPage />} />
          {/* <Route path="/LoginHeader" exact element={<LoginHeader />} /> */}
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/Feedback-Policy" element={<FeedbackPolicy />} />
          <Route path="/Terms-and-Conditions" element={<TermsConditions />} />
          <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="/get-in-touch" element={<GetInTouchHeader />} />


          <Route path="/Request" exact element={<Request />} />




          
          <Route path="/register-page" exact element={<Register />} />
          <Route path="/upcoming-events" exact element={<UpcomingEvents />} />
          <Route path="/chairman-message" exact element={<ChairmanProfile />} />
          <Route path="/Member-Reg" exact element={<MemberRegForm />} />
          <Route path="/Recent-Photos" exact element={<RecentPhotos />} />
          <Route path="/Our-Gallery" exact element={<OurGallery />} />
          <Route path="/ICAI-Registration-From" exact element={<ICAIRegistrationForm />} />
          <Route path="/Past-ChairPersons" exact element={<PastChairPersons />} />
          <Route path="/upcoming-events-details" exact element={<EventsDetails />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/articals" exact element={<Articals />} />
          <Route path="/addVacancy" exact element={<AddVacancy />} />
          <Route path="/Past-Events" exact element={<PastEvents />} />
          <Route path="/networkingZone" exact element={<NetworkingZone />} />
          <Route path="/addNetworkZone" exact element={<AddNetworkZone />} />
          <Route path="/Past-Events-Details" exact element={<PastDetails />} />
          <Route path="/Notice-Details" exact element={<NoticeDetails />} />
          <Route path="/Publication-News" exact element={<PublicationNews />} />
          <Route path="/OurResources" exact element={<OurResources />} />
          <Route path="/CalenderEvents" exact element={<CalenderEvents />} />
          <Route path="/Download-Resources" exact element={<DownloadResources />} />
          {/* <Route path="/Study-Circle" exact element={<StudyCircleDetail />} /> */}
          <Route path="/PublicationNewsletter-Detail" exact element={<PublicationNewsletterDet />} />
          <Route path="/Transaction-Status" exact element={<TransactionStatusForm />} />
          <Route path="/Icai-Cricket-League" exact element={<IcaiCricketLeague />} />
        </Routes>
      </WebsiteLayout>
    </Container>
  </BrowserRouter>
);
