import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, Button, FormGroup, Label } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import FetchApiMethod from "Helpers/FetchApiMethod";
import { FaHome } from "react-icons/fa"; // Import FontAwesome Home Icon
import { imageURL } from "../../components/URLs/URL";

const AllRequestPages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedServices, service_type_id, selectedServiceCategoryIds } = location.state || {};
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  const user_name = JSON.parse(localStorage.getItem('loginFullName')) || "";
  const user_email = JSON.parse(localStorage.getItem('useremail')) || "";
  const phone_no = JSON.parse(localStorage.getItem('loginUserPhone')) || "";


  // Extract the last part of the URL (for example, 'health-screening')
  const currentPage = location.pathname.split("/").filter(Boolean).pop();

  // Capitalize the page name
  const formattedPageName = currentPage
    ? currentPage.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
    : "Home";

  // Initialize selectedServiceIds with all service IDs when component mounts
  useEffect(() => {
    if (selectedServices?.length > 0) {
      setSelectedServiceIds(selectedServices.map(service => service.id));
    }
  }, [selectedServices]);

  // Filter services based on selectedServiceCategoryIds
  useEffect(() => {
    if (selectedServices && selectedServiceCategoryIds) {
      const filtered = selectedServices.filter(service =>
        selectedServiceCategoryIds.includes(service.services_category_id)
      );
      setFilteredServices(filtered);
    }
  }, [selectedServices, selectedServiceCategoryIds]);

  useEffect(() => {
    console.log("Selected Services:", selectedServices);
    console.log("Service Type ID:", service_type_id);
    console.log("Selected Service Category IDs:", selectedServiceCategoryIds);

    // Redirect if no services are selected
    if (!selectedServices?.length) {
      navigate(-1);
    }
  }, [selectedServices, service_type_id, selectedServiceCategoryIds, navigate]);

  const handleCheckboxChange = (serviceId) => {
    setSelectedServiceIds((prevSelected) => {
      if (prevSelected.includes(serviceId)) {
        return prevSelected.filter(id => id !== serviceId);
      } else {
        return [...prevSelected, serviceId];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedServiceIds.length === 0) {
      alert("Please select at least one service to proceed.");
      return;
    }

    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData.entries());

    // Group selected services by category ID
    const categoryMap = new Map();

    filteredServices
      .filter(service => selectedServiceIds.includes(service.id))
      .forEach(service => {
        const categoryId = parseInt(service.services_category_id);
        if (!categoryMap.has(categoryId)) {
          categoryMap.set(categoryId, {
            service_category_id: categoryId,
            service_id: []
          });
        }
        categoryMap.get(categoryId).service_id.push(service.id);
      });

    // Convert the map to an array for the API request
    const service_category = Array.from(categoryMap.values());

    // Create the API payload
    const payload = {
      customer_id: 3,
      address: formValues.address,
      postal_code: formValues.postalCode,
      customer_message: formValues.message,
      appointment_date: formValues.appointment_date,
      created_by: 1,
      service_type_id: service_type_id || 1,
      service_category: service_category
    };

    console.log("API Payload:", payload);

    try {
      const response = await FetchApiMethod(
        "createrequest",
        payload,
        "POST"
      );

      if (response.status) {
        navigate("/thank-you", {
          state: {
            // requestNumber: response.data.request_number,
            // requestNumber: response.data.request.requestcode,
            formValues,
            selectedServices: filteredServices.filter(service => 
              selectedServiceIds.includes(service.id)
            )
          }
        });
      } else {
        alert(`Error: ${response.message || 'Failed to create request'}`);
      }
    } catch (error) {
      console.error("Error submitting request:", error);
      alert("An error occurred while submitting the request. Please try again.");
    }
  };

  const groupedServices = filteredServices?.reduce((acc, service) => {
    if (!acc[service.service_category_name]) {
      acc[service.service_category_name] = [];
    }
    acc[service.service_category_name].push(service);
    return acc;
  }, {});

  return (
    <section className="home-repair-section">
      {/* <div
        style={{
          backgroundColor: "rgb(135, 206, 235)",
          height: "30vh",
          position: "fixed",
          top: 0,
          width: "100%",
          opacity: 0.5,
          zIndex: 9999,
        }}
      >
        <div className="container d-flex align-items-center justify-content-start h-50 mt-3">
          <span style={{ fontSize: "1.2rem", color: "#000" }}>
            <a href="/" style={{ textDecoration: "none", color: "#000" }}>
              <FaHome style={{ marginRight: "5px" }} /> 
              Home
            </a>
            &nbsp; &gt; &nbsp;
            {formattedPageName} 
          </span>
        </div>
      </div> */}
      <div className="container mt-5">
        <Row>
          <Col lg="6" md="12" className="mb-4">
            <div className="card shadow p-2">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="mx-1" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>Selected Services</h2>
                <Button 
                  className="addNewBtnStyle mb-2" 
                  color="primary" 
                  size="sm" 
                  block 
                  style={{ width: '130px' }} 
                  onClick={() => navigate(-1)}
                >
                  <FaPlus /> {filteredServices?.some(service => 
                    service.service_type_name === "Home Repair Services"
                  ) ? "Add More" : "Change"}
                </Button>
              </div>

              {groupedServices && Object.entries(groupedServices).length > 0 ? (
                Object.entries(groupedServices).map(([category, services]) => (
                  <div key={category} className="category-section mb-4">
                    <h5><strong>{category}</strong></h5>
                    {services.map(service => (
                      <div key={service.id} className="selected-service-item card mb-3" style={{ backgroundColor: '#f5fdfe' }}>
                        <div className="d-flex">
                          <div className="flex-shrink-0 p-2 mt-5">
                            <img
                              src={`${imageURL}${service.icon1}`}
                              alt={service.service_name}
                              className="img-fluid"
                              style={{ width: '100px', height: 'auto' }}  // Adjust the size as needed
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/placeholder-image.jpg';
                              }}
                            />
                          </div>
                          <div className="flex-grow-1 p-2">
                            <div className="card-body">
                              <h5 className="card-title mb-1" style={{fontWeight: 500,}}>{service.service_name || ""}</h5>
                              {service.package_name !== "NA" && 
                              service.package_name !== null && 
                              service.package_cost !== "NA" && 
                              service.package_cost !== null && (
                                <>
                                  <p className="mb-1">{service.package_name}</p>
                                  <p className="mb-1">Cost: ${service.package_cost}</p>
                                </>
                              )}

                              {service.details && service.details.length > 0 && (
                                <ul className="pl-3">
                                  {service.details.map((detail, idx) => (
                                    <li key={idx}>{detail.details}</li>
                                  ))}
                                </ul>
                              )}

                              {service.package_details_pdf && (
                                <p className="mt-2">
                                  <a
                                    href={`${imageURL}${service.package_details_pdf}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Details...
                                  </a>
                                </p>
                              )}

                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={selectedServiceIds.includes(service.id)}
                                    onChange={() => handleCheckboxChange(service.id)}
                                  />{' '}
                                  Select this service
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p>No services selected. Please go back and select services.</p>
              )}

            </div>
          </Col>

          <Col lg="6" md="12" className="mb-5">
            <div className="card shadow p-4">
              <h3 className="display-4 mb-5">Your Details</h3>
              <Form onSubmit={handleSubmit}>
                <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Full Name<span className="text-danger">*</span></legend>
                  <Input
                    name="full_name"
                    placeholder="Enter full name"
                    className="form-control border-0 text-capitalize" 
                    value={user_name} 
                    minLength="2"
                    maxLength="30"
                    required
                    readOnly
                  />
                </fieldset>

                <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Email<span className="text-danger">*</span></legend>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    className="form-control border-0"
                    value={user_email} 
                    required
                    readOnly
                  />
                </fieldset>

                <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Contact Number<span className="text-danger">*</span></legend>
                  <Input
                    type="number"
                    name="phone"
                    placeholder="Enter phone number"
                    className="form-control border-0"
                    value={phone_no} 
                    pattern="\(\d{3}\) \d{3}-\d{4}"
                    title="Phone number must follow the pattern (123) 456-7890"
                    required
                    readOnly
                  />
                </fieldset>

                <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Postal Code<span className="text-danger">*</span></legend>
                  <Input
                    type="number"
                    name="postalCode"
                    placeholder="Enter postal code"
                    className="form-control border-0"
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value.length > 6) {
                        e.target.value = value.slice(0, 6); // Trim to 6 digits
                      }
                    }}
                    required
                  />
                </fieldset>

                <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Address<span className="text-danger">*</span></legend>
                  <Input
                    name="address"
                    placeholder="Enter address"
                    className="form-control border-0"
                    required
                  />
                </fieldset>

                <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Message</legend>
                  <Input
                    type="textarea"
                    name="message"
                    placeholder="Enter your message"
                    className="form-control border-0"
                  />
                </fieldset>

                {/* <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Appointment Date*</legend>
                  <Input
                    type="date"
                    name="appointment_date"
                    placeholder="Select appointment date"
                    className="form-control border-0"
                    required
                  />
                </fieldset> */}

                <div className="form-group">
                <Button 
                  className="addNewBtnStyle" 
                  color="primary" 
                  size="md" 
                  block 
                  type="submit"
                  disabled={selectedServiceIds.length === 0}
                >
                  Submit Request
                </Button>
                </div>
                
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AllRequestPages;
