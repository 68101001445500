import React, { useState, useEffect } from "react";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import ButtonIcon from "views/IndexCompnents/ButtonsSection/ButtonIcon";
import { NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";
import FetchApiMethod from "Helpers/FetchApiMethod";

const user_email = JSON.parse(localStorage.getItem('useremail')) || "";
const new_user_id = JSON.parse(localStorage.getItem('new_user_id')) || "";
console.log('user_email', user_email);
console.log('user_id', new_user_id);

function MyFooter() {
  const [agreeData, setAgreeData] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [emailFilled, setEmailFilled] = useState(false);

  // Effect to check if the email is filled
  useEffect(() => {
    if (user_email) {
      setEmailFilled(true);
      setAgreeData(true); // Auto-select checkbox
      setAgreeTerms(true); // Auto-select checkbox
    } else {
      setEmailFilled(false);
      setAgreeData(false); // Deselect checkbox if email is not filled
      setAgreeTerms(false); // Deselect checkbox if email is not filled
    }
  }, [user_email]); // Dependency on user_email

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!agreeData || !agreeTerms) {
  //     setErrorMessage("Please agree to both conditions before submitting.");
  //     setSuccessMessage(""); // Clear any previous success messages
  //     return;
  //   }

  //   setErrorMessage(""); // Clear any previous error messages
  //   setSuccessMessage(""); // Clear any previous success messages

  //   // Call the API
  //   try {
  //     const eventsData = await FetchApiMethod(
  //       "updateuser",
  //       {
  //         id: new_user_id,
  //         newslatteryn: "Y",
  //       },
  //       "POST"
  //     );

  //     if (eventsData && eventsData.status) { // Check if status is true
  //       setSuccessMessage(eventsData.message); // Set success message
  //     } else {
  //       setErrorMessage(eventsData.message || "Failed to update details."); // Set error message
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //     setErrorMessage("An error occurred. Please try again."); // Set general error message
  //   }
  // };

  const handleSubmit = async (e) => {
  e.preventDefault();

  if (user_email) {
    // Unsubscribe Logic
    try {
      const eventsData = await FetchApiMethod(
        "updateuser",
        {
          id: new_user_id,
          newslatteryn: "N", // For Unsubscribe
        },
        "POST"
      );

      if (eventsData && eventsData.status) {
        setSuccessMessage("Successfully unsubscribed."); // Success message
        setAgreeData(false); // Reset checkbox
        setAgreeTerms(false); // Reset checkbox
        localStorage.removeItem('useremail'); // Clear localStorage
      } else {
        setErrorMessage(eventsData.message || "Failed to unsubscribe.");
      }
    } catch (error) {
      console.log("Error:", error);
      setErrorMessage("An error occurred. Please try again."); // General error message
    }
  } else {
    // Subscribe Logic
    if (!agreeData || !agreeTerms) {
      setErrorMessage("Please agree to both conditions before submitting.");
      setSuccessMessage(""); // Clear any previous success messages
      return;
    }

    setErrorMessage(""); // Clear any previous error messages
    setSuccessMessage(""); // Clear any previous success messages

    try {
      const eventsData = await FetchApiMethod(
        "updateuser",
        {
          id: new_user_id,
          newslatteryn: "Y", // For Subscribe
        },
        "POST"
      );

      if (eventsData && eventsData.status) {
        setSuccessMessage(eventsData.message); // Success message
      } else {
        setErrorMessage(eventsData.message || "Failed to update details.");
      }
    } catch (error) {
      console.log("Error:", error);
      setErrorMessage("An error occurred. Please try again."); // General error message
    }
  }
};


  return (
    <>
      {/* Add wave effect SVG or CSS */}
      <div className="wave-effect"></div>

      <div className="container-fluid pb-0 footer-background">
        <Row className="row-grid mb-2 border-bottom border-light border-1">
          <Col lg="8" className="text-start">
            <NavbarBrand to="/" tag={Link} className="ml-auto">
              <img
                src="https://c.animaapp.com/CPR2kjW3/img/white-on-transparent-1-2@2x.png"
                alt="Logo"
                style={{ height: "40px" }}
              />
            </NavbarBrand>
          </Col>
          <Col lg="4" className="text-start">
            <h5 className="text-white">Follow Us</h5>
            <ul className="list-unstyled mt-2 d-flex justify-content-start">
              <li className="py-2">
                <div className="d-flex align-items-center">
                  <div>
                    <ButtonIcon className="btn-primary" id="tooltipfb" target="_blank" href={`javascript:void(window.open('https://www.facebook.com/MaviGroupSingapore?mibextid=kFxxJD', '_blank'))`}>
                      <i className="fa fa-facebook-square" />
                    </ButtonIcon>
                    <UncontrolledTooltip delay={0} placement="bottom" target="tooltipfb" trigger="hover focus"> Like Us </UncontrolledTooltip>
                  </div>
                </div>
              </li>&nbsp;&nbsp;
              <li className="py-2">
                <div className="d-flex align-items-center">
                  <div>
                    <ButtonIcon className="btn-danger" id="tooltipInsta" target="_blank" href={`javascript:void(window.open('https://www.instagram.com/mavigroupsingapore/profilecard/?igsh=dXk2amVsMWE4M2ps', '_blank'))`}>
                      <i className="fa fa-instagram" />
                    </ButtonIcon>
                    <UncontrolledTooltip delay={0} placement="bottom" target="tooltipInsta" trigger="hover focus"> Follow Us </UncontrolledTooltip>
                  </div>
                </div>
              </li>&nbsp;&nbsp;
              <li className="py-2">
                <div className="d-flex align-items-center">
                  <div>
                    <ButtonIcon className="btn-default" id="tooltipIn" target="_blank" href={`javascript:void(window.open('https://www.linkedin.com/company/mavi-group-singapore/', '_blank'))`}>
                      <i className="fa fa-linkedin-square" />
                    </ButtonIcon>
                    <UncontrolledTooltip delay={0} placement="bottom" target="tooltipIn" trigger="hover focus"> Connect with Us </UncontrolledTooltip>
                  </div>
                </div>
              </li>&nbsp;&nbsp;
            </ul>
          </Col>
        </Row>
        <Row className="row-grid mb-0">
          {/* Services Section */}
          <Col lg="4">
            <h4 className="footer-title">Our Services</h4>
            <ul className="list-unstyled mt-0">
              <li className="py-0 FooterLI">
                <a href="/AllCategory?service_type_id=1" className="footer-link">Home Repair</a>
              </li>
              <li className="py-0 FooterLI">
                <a href="/AllCategory?service_type_id=2" className="footer-link">Health Screening</a>
              </li>
              <li className="py-0 FooterLI">
                <a href="/AllCategory?service_type_id=3" className="footer-link">Estate Planning</a>
              </li>
            </ul>
          </Col>

          {/* Group of Companies */}
            {/* <Col lg="3">
                <h4 className="footer-title">Our Group of Companies</h4>
                <ul className="list-unstyled mt-2">
                    <li className="py-0 FooterLI">
                        <a href="/" className="footer-link">MAVI Holding</a>
                    </li>
                    <li className="py-0 FooterLI">
                        <a href="" className="footer-link">MAVI Automotive </a>
                    </li>
                    <li className="py-0 FooterLI">
                        <a href="" className="footer-link">MAVI Care Services Singapore </a>
                    </li>
                </ul>
            </Col> */}


          {/* Contact Section */}
          <Col lg="4">
            <h4 className="footer-title">Contact Us</h4>
            <ul className="list-unstyled mt-2">
              <li className="py-0 FooterLI">
                <a href="mailto:nav.contact@mavi.services" className="footer-link">nav.contact@mavi.services</a>
              </li>
            </ul>
          </Col>

          {/* Form and Social Media */}
          <Col lg="4">
            <div className="footer-form mb-5">
              <h4 className="footer-title">Stay in the loop for the latest updates</h4>
              <form onSubmit={handleSubmit}>
                <input
                  className="formEmail"
                  type="email"
                  placeholder="Enter your email"
                  value={user_email}
                  required
                  readOnly // Make input read-only to prevent editing
                />
                <br></br>
                {!emailFilled ? (
                  <>
                    <label className="mt-3">
                      <input
                        type="checkbox"
                        checked={agreeData}
                        onChange={() => setAgreeData(!agreeData)}
                      />{" "}
                      I agree that my data is being collected and stored.
                    </label>
                    {/* <label>
                      <input
                        type="checkbox"
                        checked={agreeTerms}
                        onChange={() => setAgreeTerms(!agreeTerms)}
                      />{" "}
                      I agree to the terms & conditions and privacy policy.
                    </label> */}
                    <label>
                      <input
                        type="checkbox"
                        checked={agreeTerms}
                        onChange={() => setAgreeTerms(!agreeTerms)}
                      />{" "}
                      I agree to the{" "}
                      <a href="/Terms-and-Conditions" className="custom-link">
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a href="/Privacy-Policy" className="custom-link">
                        Privacy Policy
                      </a>.
                    </label>
                  </>
                ) : null}
                {/* Display messages based on API response */}
                {errorMessage && (
                  <p className="text-danger mt-2">{errorMessage}</p>
                )}
                {successMessage && (
                  <p className="text-success mt-2">{successMessage}</p>
                )}
                {/* <button className="addNewBtnStyle btn-lg w-50 mt-2" type="submit">
                  Submit
                </button> */}
                <button
                  className="addNewBtnStyle btn-lg w-50 mt-2"
                  type="submit"
                >
                  {user_email ? "Unsubscribe" : "Submit"}
                </button>

              </form>
            </div>
          </Col>
        </Row>
      </div>
      <div className="container-fluid pb-3 px-0 footer-background border-top border-light border-1 p">
        <Row className="align-items-center justify-content-center mt-0">
          <Col lg="4" className="text-center">
            <p className="footer-credits text-white">© 2024 Mavi Care Services Singapore Pvt. Ltd. All rights reserved.</p>
          </Col>
          <Col lg="4" className="text-center">
            <p className="footer-credits text-white">
              <a href="/Terms-and-Conditions" className="text-white" style={{ textDecoration: 'none' }}>Terms and conditions</a>&nbsp; | &nbsp;
              <a href="/Privacy-Policy" className="text-white" style={{ textDecoration: 'none' }}>Privacy Policy</a>
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MyFooter;
