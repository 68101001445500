import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand
} from "reactstrap";
import { FaChevronDown } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';  // Import the close icon

const user_name = JSON.parse(localStorage.getItem('loginFullName')) || "";
const initial = user_name.charAt(0).toUpperCase();

function LoginHeader() {
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Toggle sidebar function
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Close sidebar function
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleSignupClick = () => {
    navigate('/login', { state: { showSignUp: true } });
  };

  const handleSignInClick = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <Navbar className="navbar-dark py-0 p-1 sticky-top navbarBackground" expand="lg">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* Left side: Logo and Toggler */}
          <div className="d-flex align-items-center">
            <NavbarBrand to="/" tag={Link}>
              <img
                src="https://c.animaapp.com/CPR2kjW3/img/white-on-transparent-1-2@2x.png"
                alt="Logo"
                style={{ height: "25px" }}
              />
            </NavbarBrand>

            {/* Hamburger icon for mobile */}
            <button className="navbar-toggler" onClick={toggleSidebar}>
              <span className="navbar-toggler-icon" />
            </button>
          </div>

          {/* Right side: Sign in/Sign up and Get in Touch buttons */}
          <div className="d-flex align-items-center hiddenLoginButtons">
          {!user_name && (
              <>
                {/* <Button
                  variant="primary"
                  style={{ backgroundColor: '#FCAF3D', border: 'none', marginRight: '15px' }}
                  className="custom-button rounded-pill text-white hiddenLoginButtons"
                  onClick={handleSignInClick}
                >
                  SIGN IN
                </Button>
                <Button
                  variant="primary"
                  style={{ backgroundColor: '#FCAF3D', border: 'none', marginRight: '15px' }}
                  className="custom-button rounded-pill text-white hiddenLoginButtons"
                  onClick={handleSignupClick}
                >
                  SIGN UP
                </Button> */}
              </>
            )}
            <Button
              variant="primary"
              style={{ backgroundColor: '#FCAF3D', border: 'none', marginRight: '0px' }}
              className="custom-button rounded-pill text-white TouchButton"
              onClick={() => navigate('/get-in-touch')}
            >
              GET IN TOUCH
            </Button>



            {user_name && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <div
                    className="rounded-circle d-flex justify-content-center align-items-center"
                    style={{ backgroundColor: '#FCAF3D', width: '40px', height: '40px', color: 'white', cursor: 'pointer' }}
                  >
                    {initial}
                  </div>
                </DropdownToggle>
                <DropdownMenu end className="proflieMenu">
                  <DropdownItem onClick={() => navigate('/profile')}>View Profile</DropdownItem>
                  <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            <UncontrolledDropdown nav inNavbar className="hiddenLoginButtons">
              <DropdownToggle nav style={{ display: 'flex', alignItems: 'center', color: 'white'}}>
                <span style={{ marginRight: '5px' }}>English</span>
                <FaChevronDown />
              </DropdownToggle>
              <DropdownMenu end style={{minWidth: '7rem'}}>
                <DropdownItem onClick={() => console.log('English selected')}>English</DropdownItem>
                <DropdownItem onClick={() => console.log('Chinese selected')}>Chinese</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Navbar>

      {/* Sidebar for mobile */}
      <div className={`sidebar ${isSidebarOpen ? 'show' : ''}`}>
        {/* Close icon */}
        <div className="sidebar-header d-flex justify-content-between align-items-center">
          <span className="sidebar-title"></span>
          <AiOutlineClose className="close-icon" size={24} onClick={closeSidebar} />
        </div>

        {/* Copy the same navigation links for the sidebar */}
        <Nav className="flex-column p-3" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav to="/" tag={Link} className="py-2" onClick={closeSidebar}>
              Home
            </DropdownToggle>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="py-2">
              Our Services &nbsp;<FaChevronDown />
            </DropdownToggle>
            <DropdownMenu aria-labelledby="navbar-primary_dropdown_1">
              <DropdownItem to="/AllCategory?service_type_id=1" tag={Link} onClick={closeSidebar}>
                Home Repair
              </DropdownItem>
              <DropdownItem to="/AllCategory?service_type_id=2" tag={Link} onClick={closeSidebar}>
                Health Screening
              </DropdownItem>
              <DropdownItem to="/AllCategory?service_type_id=3" tag={Link} onClick={closeSidebar}>
                Estate Planning
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav to="/" tag={Link} className="py-2" onClick={closeSidebar}>
              Our Group of Companies
            </DropdownToggle>
          </UncontrolledDropdown><br/>

          {/* {!user_name && (
              <>
                <Button
                    variant="primary"
                    style={{ backgroundColor: '#FCAF3D', border: 'none', marginRight: '15px' }}
                    className="custom-button rounded-pill text-white"
                    onClick={handleSignInClick}
                  >
                    SIGN IN
                  </Button><br/>
                  <Button
                    variant="primary"
                    style={{ backgroundColor: '#FCAF3D', border: 'none', marginRight: '15px' }}
                    className="custom-button rounded-pill text-white"
                    onClick={handleSignupClick}
                  >
                    SIGN UP
                  </Button>
              </>
            )} */}
        </Nav>
      </div>

      {/* Overlay when the sidebar is open */}
      {isSidebarOpen && <div className="overlay show" onClick={closeSidebar}></div>}
    </>
  );
}

export default LoginHeader;





















