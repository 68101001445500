import React, { useState, useEffect } from "react";
import FetchApiMethod from "Helpers/FetchApiMethod";

function TermsConditions() {
  const [contactDetails, setContactDetails] = useState(null);
  const [tncText, setTncText] = useState("");

  const fetchContactDetails = async () => {
    try {
      const Gettncfeedbackpolicy = FetchApiMethod(
        "Gettncfeedbackpolicy",
        { id: 1 },
        "POST"
      );

      const respodata = await Gettncfeedbackpolicy;
      setContactDetails(respodata.data || {});
      setTncText(respodata.data?.tnc_text || "");
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  useEffect(() => {
    fetchContactDetails();
  }, []);

  return (
    <>
      <section className="feedback-policy-section">
        <div className="container">
        <h1 className="mainHeading text-center">Terms and Conditions</h1>
          <div
            className="feedback-policy-text"
            dangerouslySetInnerHTML={{ __html: tncText }}
          ></div>
        </div>
      </section>
    </>
  );
}

export default TermsConditions;
