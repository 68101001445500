import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Process from "assets/img/theme/Process.png";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { imageURL } from "../../components/URLs/URL";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const AllServiceCategory = ({ servicesData }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [isMobile, setIsMobile] = useState(false);
  

  useEffect(() => {
    // Detect screen width and set the state
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Check on initial load
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,  // Show 3 images at a time
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 768,  // For mobile screens below 768px width
  //       settings: {
  //         slidesToShow: 3,  // Show 3 slides at a time
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 576,  // For smaller mobile screens below 576px
  //       settings: {
  //         slidesToShow: 2,  // Show 2 slides at a time on very small screens
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

   const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 3, // Show 3 images at a time
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Auto-scroll every 3 seconds
    responsive: [
      {
        breakpoint: 768, // For mobile screens below 768px width
        settings: {
          slidesToShow: 3, // Show 3 slides at a time
          slidesToScroll: 1,
          autoplay: true, // Enable autoplay on mobile
          autoplaySpeed: 2000, // Auto-scroll every 3 seconds
        },
      },
      {
        breakpoint: 576, // For smaller mobile screens below 576px
        settings: {
          slidesToShow: 2, // Show 2 slides at a time on very small screens
          slidesToScroll: 1,
          autoplay: true, // Enable autoplay on small screens
          autoplaySpeed: 2000, // Auto-scroll every 3 seconds
        },
      },
    ],
  };

  // Get service_type_id from the URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const service_type_id = queryParams.get("service_type_id"); // Extract the service_type_id from query params

  const navigate = useNavigate(); // Hook to navigate between pages

  const handleSearch = (event) => {
    event.preventDefault();
    alert(`You searched for: ${searchQuery}`);
  };

  const selectService = async (service) => {
    console.log(service, 'line no 29');
    
    setSelectedService(service);
    try {
      const eventsData = await FetchApiMethod(
        "getallservices",
        {
          service_type_id: service_type_id || 1,
          service_category_id: service.services_category_id,
          status: "Active",
        },
        "POST"
      );
      if (eventsData.status) {
        setServiceDetails(eventsData.data);
      } else {
        alert("Failed to fetch service details");
      }
    } catch (error) {
      console.error("Error fetching service details:", error);
    }
  };
  



  const handleCardClick = (service) => {
  handleCheckboxChange(service);
};



  const handleCheckboxChange = (service) => {
    setSelectedServiceIds((prevIds) =>
      prevIds.includes(service.id)
        ? prevIds.filter((id) => id !== service.id)
        : [...prevIds, service.id]
    );
  
    setSelectedServices((prevServices) =>
      prevServices.some((s) => s.id === service.id)
        ? prevServices.filter((s) => s.id !== service.id)
        : [...prevServices, service]
    );
  };
  

  
  const handleButtonClick = () => {
    if (selectedServices.length > 0) {
      // Extract service_category_ids from the selected services
      console.log(selectedServices, 'line no 117');
      
      const selectedServiceCategoryIds = selectedServices.map(service => service.services_category_id);
  
      console.log(selectedServiceCategoryIds, "deepak");
      const user_id = JSON.parse(localStorage.getItem('user_type_id'));
      console.log('shubhamsingh',user_id);
      
      if (user_id===3) {
        navigate('/Request', {
          state: {
            selectedServices,
            service_type_id,
            selectedServiceCategoryIds, 
          },
        });
      } else {
        
        navigate("/login", { state: { showSignIn: true } });
      }
    } else {
      alert('No services selected');
    }
  };

//   const handleButtonClick = () => {
//   if (selectedServiceIds.length > 0) {
//     // Map the selected service IDs to get the selected services
//     const selectedServices = serviceDetails.filter(service =>
//       selectedServiceIds.includes(service.id)
//     );

//     console.log(selectedServices, 'line no 117');
    
//     // Extract service_category_ids from the selected services
//     const selectedServiceCategoryIds = selectedServices.map(service => service.services_category_id);

//     console.log(selectedServiceCategoryIds, "deepak");

//     const user_id = JSON.parse(localStorage.getItem('user_type_id'));
//     console.log('shubhamsingh', user_id);

//     if (user_id === 3) {
//       navigate('/Request', {
//         state: {
//           selectedServices,
//           service_type_id,
//           selectedServiceCategoryIds,
//         },
//       });
//     } else {
//       navigate("/login", { state: { showSignIn: true } });
//     }
//   } else {
//     alert('No services selected');
//   }
// };




  const { service_type, service_categories } = servicesData;

  useEffect(() => {
    // You can also fetch data based on service_type_id when component mounts or changes
    console.log("Current service_type_id:", service_type_id);
  }, [service_type_id]);

  return (
    <section className="home-repair-section">
      <div className="container-fluid p-0">
        <div className="services-section container">
          <div className="text-center mt-5">
            {service_type && <p>{service_type.description1 || ""}</p>}

            {service_type?.text1 === "Health Screening Services" ? (
              <h2 className="mt-5">OUR Packages</h2>
            ) : (
              <h2 className="mt-5">Our Services</h2>
            )}

            {service_type && <p>{service_type.description2 || ""}</p>}
          </div>
          



          <div className="service-categories-container">
                {isMobile ? (
                  // Render Slider for mobile screens
                  <Slider {...sliderSettings}>
                    {service_categories?.map((service) => (
                      <div key={service.services_category_id}>
                        {/* <div
                          className="service-card d-flex flex-column align-items-center"
                          onClick={() => selectService(service)}
                          style={{ cursor: "pointer" }}
                        > */}
                        <div
                            className="service-card d-flex flex-column align-items-center"
                            onClick={() => handleCardClick(service)}
                            style={{ cursor: "pointer" }}
                          >

                          <div className="service-icon mt-5">
                          <img
                            src={`${imageURL}${service.icon}`}
                            alt={service.service_category_name}
                            className="img-fluid"
                          />

                          </div>
                          <h5 className="mt-3 text-center" style={{fontWeight: 'bold',}}>
                            {service.service_category_name}
                            </h5>
                            <h5 className="text-center">
                            {service.description !== "NA" && service.description !== null
                              ? service.description
                              : ""}
                          </h5>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  // Render normal layout for desktop screens
                  <div className="row text-center justify-content-center mb-5">
                    {service_categories?.map((service) => (
                      <div
                        className="col-6 col-md-4 col-lg-2 mb-4"
                        key={service.services_category_id}
                      >
                        <div
                          className="service-card d-flex flex-column align-items-center"
                          onClick={() => selectService(service)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="service-icon mt-5">
                            <img
                              src={`${imageURL}${service.icon}`}
                              alt={service.service_category_name}
                              className="img-fluid"
                            />
                          </div>
                          <h6 className="mt-3 text-center" style={{fontWeight: 500,}}>
                            {service.service_category_name}
                            </h6>
                            <span className="text-center">
                            {service.description !== "NA" && service.description !== null
                              ? service.description
                              : ""}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Details of sub category */}
             {serviceDetails && (
                <div className="service-details mt-5">
                  <div className="row justify-content-center">
                    {serviceDetails.map((service) => {
                      const { details } = service;
                      const isChecked = selectedServiceIds.includes(service.id);

                      return (
                        <div key={service.id} className="col-md-6 mb-2">
                          <div
                              className="card Mainservice-card position-relative"
                              onClick={() => handleCheckboxChange(service)} // Call the checkbox toggle function
                              style={{ cursor: "pointer" }}
                            >
                            <div className="row g-0">
                              {/* Left Column for Image */}
                              <div className="col-4 pr-0 d-flex align-items-center justify-content-center">
                                <img
                                  src={`${imageURL}${service.icon1}`}
                                  alt={service.service_name}
                                  className="w-100 p-2"
                                />
                              </div>
                              
                              {/* Right Column for Content */}
                              <div className="col-8 pl-2">
                                <div className="card-body pl-2 py-2 ">
                                  <div className="card-title text-bold mb-1">
                                    <div className="row justify-content-between">
                                      <div className="col h5 mb-0 text-bold" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", fontWeight: 500,}}>
                                        {service.service_name !== "NA" && service.service_name !== null ? service.service_name : ""}
                                      </div>

                                      <div className="col text-right">
                                        {/* <input
                                          type="checkbox"
                                          id={service.id}
                                          name={service.id}
                                          value={service.id}
                                          checked={isChecked}
                                          onChange={() => handleCheckboxChange(service)} // Pass full service object
                                        /> */}
                                        <input
                                            type="checkbox"
                                            id={service.id}
                                            name={service.id}
                                            value={service.id}
                                            checked={isChecked}
                                            onClick={(event) => event.stopPropagation()} // Prevent card click propagation
                                            onChange={() => handleCheckboxChange(service)} // Toggle the checkbox
                                          />
                                      </div>
                                    </div>

                                    {service.package_name !== "NA" && service.package_name !== null && service.package_cost !== "NA" && service.package_cost !== null && (
                                      <>
                                        <div className="h5 mb-0">
                                          {service.package_name}
                                        </div>
                                        <div className="h5 mb-0">
                                          Cost: ${service.package_cost}
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  <div className="">
                                    <div className="col">
                                      <ul className="pl-4">
                                        {details.slice(0, 3).map((detail) => (
                                          <li key={detail.id}>{detail.details}</li>
                                        ))}
                                      </ul>
                                    </div>

                                    {details.length > 3 && (
                                      <div className="col">
                                        <ul className="">
                                          {details.slice(3).map((detail) => (
                                            <li className="liDetails" key={detail.id}>{detail.details}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}

                                    {service.package_details_pdf && (
                                      <p style={{ textAlign: "left" }}>
                                        <a
                                          href={`${imageURL}${service.package_details_pdf}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Details...
                                        </a>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
        </div>

        <div className="search-section text-center my-5">
          <h3>What are you looking for</h3>
          <form onSubmit={handleSearch}>
            <input
              type="text"
              className="search-input text-capitalize"
              placeholder="Search packages..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                padding: "10px",
                width: "70%",
                borderRadius: "25px",
                border: "1px solid #ccc",
                marginTop: "20px",
                maxWidth: "500px",
              }}
            />
            <button
              type="submit"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                position: "relative",
                left: "-50px",
              }}
            >
              <FaSearch
                style={{ width: "20px", height: "20px", color: "#86aff0" }}
              />
            </button>
          </form>
        </div>

        {/* {selectedServiceIds.length > 0 && (
          <div className="text-center mb-5">
            <button
              className="appointment-btn btn-lg mt-4"
              onClick={handleButtonClick}
            >
              {service_categories?.button_test || "BOOK YOUR SERVICE"}
            </button>
          </div>
        )} */}

          {selectedServiceIds.length > 0 && (
            <div className="text-center mb-5">
              <button className="appointment-btn btn-lg mt-4" onClick={handleButtonClick}>
                BOOK YOUR SERVICE
              </button>
            </div>
          )}

        <img
          alt="scroll top"
          className="img-fluid"
          src={Process}
          style={{
            height: "50%",
            width: "100%",
            display: service_type?.service_type_id === 1 ? "block" : "none"
          }}
        />
      </div>
    </section>
  );
};

export default AllServiceCategory;