import React, { useState, useEffect } from "react";
import FetchApiMethod from "Helpers/FetchApiMethod";

function FeedbackPolicy() {
  const [contactDetails, setContactDetails] = useState(null);
  const [feedbackPolicyText, setFeedbackPolicyText] = useState("");

  const fetchContactDetails = async () => {
    try {
      const GetFeedbackPolicy = FetchApiMethod(
        "GetFeedbackPolicy",
        { id: 1 },
        "POST"
      );

      const respodata = await GetFeedbackPolicy;
      setContactDetails(respodata.data || {});
      setFeedbackPolicyText(respodata.data?.feedbackpolicy_text || "");
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  useEffect(() => {
    fetchContactDetails();
  }, []);

  return (
    <>
      <section className="feedback-policy-section">
        <div className="container">
          <h1 className="mainHeading text-center">Feedback Policy</h1>
          <div
            className="feedback-policy-text"
            dangerouslySetInnerHTML={{ __html: feedbackPolicyText }}
          ></div>
        </div>
      </section>
    </>
  );
}

export default FeedbackPolicy;
