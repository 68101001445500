import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { imageURL } from "../../components/URLs/URL";
const HomeRepairServices = ({ servicesData }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    alert(`You searched for: ${searchQuery}`);
  };

  return (
    <section className="home-repair-section">
      <div className="container-fluid p-0">
        {/* New Services Section */}
        <div className="services-section px-6">
          <div className="row text-center px-6">
            {servicesData?.map((service) => (
              <div className="col-6 col-md-6 col-lg-4 iconCSS iconCSSMobile" key={service.id}>
                <div className="service-box p-4">
                  <img
                    src={`${imageURL}${service.icon}`}
                    alt={service.service_name}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* About Section */}
        <div className="about-section px-6">
          <div className="row mb-3 mt-3">
            <div className="col-md-5 col-12">
              <h4 className="text-white mt-6">About MAVI Care</h4>
            </div>
            <div className="col-md-7 col-12 text-left">
              <p className="mb-4">
                Word of mouth... Search engines... Blogs...<br />
                Are you bogged down by the tedious process of searching and selecting <br />
                the best services in the market?
              </p>
              <p>
                MAVI Care is precisely here to do that for you! We identify,<br /> collate and rank experienced and reliable service providers<br /> based on quality, affordability, and customization.
              </p>
            </div>
          </div>
        </div>

        {/* Display fetched services with alternating layout and background */}
        {servicesData?.map((service, index) => (
          <div
            key={service.id}
            className={`home-repair-content px-6 mt-6 d-flex ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"} flex-wrap`}
            style={{
              background: index % 2 === 0 ? "#ffffff" : "#f9f8f8",
              padding: "20px",
            }}
          >
            <div className="image-section col-md-6 col-12">
              <img
                src={`${imageURL}${service.image}`}
                alt={service.service_name}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="text-section col-md-6 col-12 px-6">
              <h3>{service.service_name}</h3>
              <div className="text-justify" dangerouslySetInnerHTML={{ __html: service.description }} />
              <button className="appointment-btn btn-lg mt-4">{service.button_text}</button>
            </div>
          </div>
        ))}

        {/* Search Section */}
        <div className="search-section text-center my-5">
          <h3>What are you looking for</h3>
          <form onSubmit={handleSearch}>
            <input
              type="text"
              className="search-input"
              placeholder="Type your query..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                padding: "10px",
                width: "70%",
                borderRadius: "25px",
                border: "1px solid #ccc",
                marginTop: "20px",
                maxWidth: "500px",
              }}
            />
            <button
              type="submit"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                position: "relative",
                left: "-50px",
              }}
            >
              <FaSearch style={{ width: "20px", height: "20px", color: "#86aff0" }} />
            </button>
          </form>
        </div>
      </div>

      {/* Responsive CSS */}
      {/* <style jsx>{`
        
      `}</style> */}
    </section>
  );
};

export default HomeRepairServices;
