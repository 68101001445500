import React, { useState } from "react";
import { Button, Row, Col, Form, Input } from 'reactstrap';
import Touch from "assets/img/theme/Touch.png";
import { FaUpload } from 'react-icons/fa';
import ReCAPTCHA from "react-google-recaptcha";
import FetchApiMethod from "Helpers/FetchApiMethod";

function GetInTouchHeader() {
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [iconPreview, setIconPreview] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [reason, setReason] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [message, setMessage] = useState("");
  const [imgBase64, setImgBase64] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const storedUserName = JSON.parse(localStorage.getItem('loginFullName'));
  const storedUserEmail = JSON.parse(localStorage.getItem('useremail'));
  const storedPhoneNo = JSON.parse(localStorage.getItem('loginUserPhone'));
  const newUserId = JSON.parse(localStorage.getItem('new_user_id'));

  const [formData, setFormData] = useState({
    user_name: storedUserName || "",
    user_email: storedUserEmail || "",
    phone_no: storedPhoneNo || ""
  });

  // Handle file upload and convert to base64
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "image/png") {
      setUploadedFileName(file.name);
      setIconPreview(URL.createObjectURL(file));

      const reader = new FileReader();
      reader.onloadend = () => {
        setImgBase64(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, file: "Please upload a PNG image." }));
    }
  };

  // Handle reCAPTCHA verification
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  // Handle form input changes
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };


  // Handle form input changes
    const handleInputChange = (e) => {
      const { name, value } = e.target;

      if (name === "phone_no") {
        // Restrict the input to a maximum of 10 digits
          const phoneNo = value.replace(/[^0-9]/g, "").slice(0, 10);
          
        setFormData((prevData) => ({ ...prevData, phone_no: phoneNo }));

        // Validate Contact Number only if not blank
        if (phoneNo && phoneNo.length !== 10) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone_no: "Contact number must be exactly 10 digits.",
          }));
        } else {
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.phone_no;
            return newErrors;
          });
        }
      } else {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
    };




  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    if (!recaptchaToken) {
      formErrors.recaptcha = "Please verify that you're not a robot.";
    }

    if (!reason.trim()) {
      formErrors.reason = "Reason is required.";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    try {
      const eventsData = await FetchApiMethod(
        "CreateContactUs",
        {
          "customer_name": formData.user_name,
          "email": formData.user_email,
          "customer_contact": formData.phone_no,
          "reason": reason,
          "vendor_job_number": jobNumber,
          "message": message,
          "created_by": newUserId,
          "img": imgBase64,
        },
        "POST"
      );

      if (eventsData && eventsData.status) {
        setSuccessMessage(eventsData.message);

        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, form: "Failed to submit the form." }));
      }
    } catch (error) {
      console.log("Error:", error);
      setErrors((prevErrors) => ({ ...prevErrors, form: "An error occurred during submission." }));
    }
  };

  return (
    <>
      {/* <section className="section pt-4 px-6" style={{ backgroundColor: "#f8f9f9" }}> */}
      <section className="section pt-4 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#f8f9f9" }}>
        <div className="container-fluid">
          <Row className="justify-content-center align-items-center">
            <Col lg="5" className="text-center">
              <img
                alt="scroll top"
                className="img-fluid getintouchImg d-flex justify-content-center"
                src={Touch}
                style={{ height: 'auto', width: '120%' }}
              />
            </Col>

            <Col lg="7" className=" justify-content-center">
              <h4 className="text-center mb-5">Get in Touch</h4>
              <Form onSubmit={handleSubmit}>

                {/* Full Name */}
                <fieldset className="form-group border formBorder rounded mb-5">
                  <legend className="w-auto ml-2 mb-0">Full Name<span className="text-danger">*</span></legend>
                  <Input
                    name="user_name"
                    placeholder="Enter full name"
                    className="form-control border-0 text-capitalize"
                    value={formData.user_name}
                    onChange={handleInputChange}
                    required
                  />
                </fieldset>

                {/* Email */}
                <fieldset className="form-group border formBorder rounded mb-3">
                  <legend className="w-auto ml-2 mb-0">Email<span className="text-danger">*</span></legend>
                  <Input
                    type="email"
                    name="user_email"
                    placeholder="Enter email id"
                    className="form-control border-0"
                    value={formData.user_email}
                    onChange={handleInputChange}
                    required
                  />
                </fieldset>

                {/* Contact Number */}
                {/* <fieldset className="form-group border formBorder rounded mt-3">
                  <legend className="w-auto ml-2 mb-0">Contact Number*</legend>
                  <Input
                    type="number"
                    name="phone_no"
                    placeholder="+65 | Enter contact number"
                    className="form-control border-0"
                    value={formData.phone_no}
                    onChange={handleInputChange}
                    required
                  />
                </fieldset> */}

                <fieldset className="form-group border formBorder rounded mt-3">
                  <legend className="w-auto ml-2 mb-0">Contact Number<span className="text-danger">*</span></legend>
                  <Input
                    type="number"
                    name="phone_no"
                    placeholder="+65 | Enter contact number"
                    className="form-control border-0"
                    value={formData.phone_no}
                    onChange={handleInputChange}
                    required
                  />
                </fieldset>
                {errors.phone_no && <div style={{ color: "red" }}>{errors.phone_no}</div>}


                {/* Reason */}
                <fieldset className="form-group border formBorder rounded mt-3">
                  <legend className="w-auto ml-2 mb-0">Reason<span className="text-danger">*</span></legend>
                  <Input
                    name="reason"
                    placeholder="Enter reason"
                    className="form-control border-0 text-capitalize"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    minLength="2"
                    maxLength="30"
                    required
                  />
                  {errors.reason && <div style={{ color: "red" }}>{errors.reason}</div>}
                </fieldset>

                {/* Message */}
                <fieldset className="form-group border formBorder rounded mb-3 mt-3">
                  <legend className="w-auto ml-2 mb-0">Message</legend>
                  <Input
                    type="textarea"
                    name="message"
                    placeholder="Enter message"
                    className="form-control border-0 text-capitalize"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </fieldset>

                {/* Service Vendor Job Number */}
                <fieldset className="form-group border formBorder rounded mt-3">
                  <legend className="w-auto ml-2 mb-0">Service Vendor Job Number</legend>
                  <Input
                    type="number"
                    name="jobNumber"
                    placeholder="Enter service vendor job number"
                    className="form-control border-0"
                    value={jobNumber}
                    onChange={(e) => setJobNumber(e.target.value)}
                  />
                </fieldset>

                {/* Icon Upload */}
                <div className="mt-4">
                  <legend className="w-auto px-1 ml-2 mb-0">Upload Image <span className="text-danger">*</span></legend>
                  <div>
                    <input
                      type="file"
                      id="icon-upload"
                      name="icon"
                      hidden
                      accept=".png"
                      onChange={handleFileChange}
                      required
                    />
                    <div className="d-flex">
                      <button
                        type="button"
                        onClick={() => document.getElementById('icon-upload').click()}
                        className="btn w-50 btn-outline-warning btnColour"
                      >
                        <FaUpload /> Upload
                      </button>
                      {uploadedFileName && (
                        <button type="button" className="btn w-50 btn-outline-primary d-flex align-items-center justify-content-center">
                          {iconPreview && (
                            <img
                              src={iconPreview}
                              alt="Uploaded Icon"
                              style={{ width: '40px', height: '30px', marginRight: '8px' }}
                            />
                          )}
                          {uploadedFileName}
                        </button>
                      )}
                    </div>
                    <div style={{ color: "lightgray", fontSize: "13px" }}>
                      Formats allowed are png, maximum file size allowed is 5 MB
                    </div>
                    {errors.file && <div style={{ color: "red" }}>{errors.file}</div>}
                  </div>
                </div>

                {/* reCAPTCHA */}
                <ReCAPTCHA
                // sitekey="6Le7PWMqAAAAAK8ogFONPlIR3OPxIBR95SWa1qei" // For Local
                  sitekey="6Lf43WQqAAAAADiXHNl8nI_YK0Qwva__fHQeYiUH"
                  onChange={handleRecaptchaChange}
                  className="mb-3"
                />
                {errors.recaptcha && <div style={{ color: "red" }}>{errors.recaptcha}</div>}

                {/* Submit Button */}
                <Button className="addNewBtnStyle" color="primary" size="lg" block style={{ width: '150px' }}>
                  Submit
                </Button>

                 {/* Display success message */}
                 {successMessage && <div style={{ color: "green", textAlign: "center", marginTop: "10px" }}>{successMessage}</div>}

                {/* Display form error */}
                {errors.form && <div style={{ color: "red", textAlign: "center", marginTop: "10px" }}>{errors.form}</div>}

              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default GetInTouchHeader;
