import React, { useState, useEffect } from "react";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

function PrivacyPolicy() {
    //   const [banners, setBanners] = useState([]);
    const [contactDetails, setContactDetails] = useState(null);

    //   const fetchBanners = async () => {
    //     try {
    //       const getBannerswebsite = FetchApiMethod(
    //         "getBannerswebsite?postman=Y&banner_type=AboutUs",
    //         "POST"
    //       );

    //       const respodata = await getBannerswebsite;
    //       setBanners(respodata.data || []);
    //     } catch (error) {
    //       console.error("Error fetching banners:", error);
    //     }
    //   };

    const fetchContactDetails = async () => {
        try {
            const getAboutMaster = FetchApiMethod(
                "membership/getAboutMaster?postman=Y",
                "POST"
            );

            const respodata = await getAboutMaster;
            setContactDetails(respodata.data[0] || null);
        } catch (error) {
            console.error("Error fetching contact details:", error);
        }
    };

    useEffect(() => {
        // fetchBanners();
        fetchContactDetails();
    }, []);

    return (
        <>

            <section className="contact-section bg-fs">
        <div className="container">
          <h1 className="mainHeading text-center">Privacy Policy</h1>
          <h2 className="aboutHeading">Privacy Statement (For General Public)</h2>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 order-2 mt-2 mb-3">
              <div className="inner-column">
                {/* <div className="text-justify">
                  <p className="card-text">Your Personal Data is important to us. The purpose of this document ("Privacy Statement") is to inform you of how the Mavi Group of companies manages Personal Data (as defined below) which is subject to the Personal Data Protection Act 2012 (India)  and The Information Technology Act 2000 (India), whichever is applicable to you ("the Act"). </p>
                  <p className="card-text">Please take a moment to read this Privacy Statement so that you know and understand the purposes for which we collect, use and disclose your Personal Data. </p>
                  <p className="card-text">By interacting with us via this website, submitting information to us, enrolling or signing up for any products or services offered by us or any insurer or client on whose behalf we act, you agree and consent to the Mavi Group of companies (namely Mavi Holding Pte. Ltd. and its subsidiaries and associated companies) (collectively, the "Companies"), as well as their respective representatives and/or agents ("Representatives") (the Companies and Representatives collectively referred to herein as "Mavi", "us", "we" or "our") collecting, using, disclosing and sharing amongst themselves your Personal Data, and disclosing such Personal Data to the Companies' authorised service providers and relevant third parties to be processed in the relevant manner and for any of the purposes set forth in this Privacy Statement. In addition, this Privacy Statement will also provide you more information on the bases upon which we collect, use and/or disclose your Personal Data without your consent, where permitted by applicable law.</p>
                  <p className="card-text">This Privacy Statement supplements but does not supersede nor replace any other consents you may have previously provided to Mavi in respect of your Personal Data, and your consents herein are cumulative and additional to any rights which any of the Companies may have at law to collect, use or disclose your Personal Data.</p>
                  <p className="card-text">Mavi may from time to time update this Privacy Statement to ensure that this Privacy Statement is consistent with our future developments, industry trends and/or any changes in legal or regulatory requirements. Subject to your rights at law, you agree to be bound by the prevailing terms of this Privacy Statement as updated from time to time on our website . Please check back regularly for updated information on the handling of your Personal Data.</p>
                  <h2 className="aboutHeading">1. Personal Data</h2>
                  <p className="card-text">In this Privacy Statement, "Personal Data" refers to any data, whether true or not, about an individual who can be identified (a) from that data; or (b) from that data and other information to which the organisation has or is likely to have access, including data in our records as may be updated from time to time.</p>
                  <p className="card-text">Examples of such Personal Data you may provide to us include (depending on the nature of your interaction with us) your name, NRIC, passport or other identification number, telephone number(s), mailing address, email address, transactional data and any other information relating to any individuals which you have provided us in any forms you may have submitted to us, (including in the form of biometric data), or via other forms of interaction with you.</p>
                  <h2 className="aboutHeading">2. Collection of Personal Data</h2>
                </div> */}
                <div className="privacy-content">
                {/* <h2>PRIVACY POLICY</h2>
                <h4>Privacy Statement (For General Public)</h4> */}
                <p>Your Personal Data is important to us. The purpose of this document ("<strong>Privacy Statement</strong>") is to inform you of how the Mavi Group of companies manages Personal Data (as defined below) which is subject to the Personal Data Protection Act 2012 (India)&nbsp; and The Information Technology Act 2000 (India), whichever is applicable to you ("the Act").&nbsp;</p>
                <p>Please take a moment to read this Privacy Statement so that you know and understand the purposes for which we collect, use and disclose your Personal Data.&nbsp;</p>
                <p>By interacting with us via this website, submitting information to us, enrolling or signing up for any products or services offered by us or any insurer or client on whose behalf we act, you agree and consent to the Mavi Group of companies (namely Mavi Holding Pte. Ltd. and its subsidiaries and associated companies) (collectively, the "<strong>Companies</strong>"), as well as their respective representatives and/or agents ("<strong>Representatives</strong>") (the Companies and Representatives collectively referred to herein as "<strong>Mavi</strong>", "<strong>us</strong>", "<strong>we</strong>" or "<strong>our</strong>") collecting, using, disclosing and sharing amongst themselves your Personal Data, and disclosing such Personal Data to the Companies' authorised service providers and relevant third parties to be processed in the relevant manner and for any of the purposes set forth in this Privacy Statement. In addition, this Privacy Statement will also provide you more information on the bases upon which we collect, use and/or disclose your Personal Data without your consent, where permitted by applicable law.</p>
                <p>This Privacy Statement supplements but does not supersede nor replace any other consents you may have previously provided to Mavi in respect of your Personal Data, and your consents herein are cumulative and additional to any rights which any of the Companies may have at law to collect, use or disclose your Personal Data.</p>
                <p>Mavi may from time to time update this Privacy Statement to ensure that this Privacy Statement is consistent with our future developments, industry trends and/or any changes in legal or regulatory requirements. Subject to your rights at law, you agree to be bound by the prevailing terms of this Privacy Statement as updated from time to time on our website&nbsp;. Please check back regularly for updated information on the handling of your Personal Data.</p>
                <h4>1. Personal Data</h4>
                <p>In this Privacy Statement, "<strong>Personal Data</strong>" refers to any data, whether true or not, about an individual who can be identified (a) from that data; or (b) from that data and other information to which the organisation has or is likely to have access, including data in our records as may be updated from time to time.</p>
                <p>Examples of such Personal Data you may provide to us include (depending on the nature of your interaction with us) your name, NRIC, passport or other identification number, telephone number(s), mailing address, email address, transactional data and any other information relating to any individuals which you have provided us in any forms you may have submitted to us, (including in the form of biometric data), or via other forms of interaction with you.</p>
                <h4>2. Collection of Personal Data</h4>
                <p>2.1&nbsp; Generally, we collect Personal Data in the following ways:</p>
                <ol className="privacy-order-list">
                    <li className="privacy-list">&nbsp;when you submit any form, including but not limited to application, declaration, proposal or referral forms;</li>
                    <li className="privacy-list">&nbsp;when you enter into any agreement or provide other documentation or information in respect of your interactions and transactions with us, or when you use our services;</li>
                    <li className="privacy-list">&nbsp;when you interact with our staff or third party providers, including customer service officers, relationship managers and other representatives, for e.g. via telephone calls (which may be recorded), letters, fax, face-to-face meetings, digital platforms (including social media) and email;</li>
                    <li className="privacy-list">&nbsp;when you use some of our services provided through online and other technology platforms, eg. websites and apps including when you establish any online accounts with us;</li>
                    <li className="privacy-list">&nbsp;when you use our electronic services, or interact with us via our websites or use services on our websites;</li>
                    <li className="privacy-list">&nbsp;when a policyholder/ contract holder takes up an insurance policy or contract for your benefit or appoints you as the insured/ contract holder;</li>
                    <li className="privacy-list">&nbsp;when you request that we contact you, be included in an email or other mailing list; or when you respond to our request for additional Personal Data, our promotions and other initiatives;</li>
                    <li className="privacy-list">&nbsp;when you are contacted by, and respond to, our marketing representatives and agents and other service providers;</li>
                    <li className="privacy-list">&nbsp;when you submit an employment application or when you provide documents or information including your resume and/or curriculum vitae in connection with any appointment as an officer, director, representative or any other position;</li>
                    <li className="privacy-list">&nbsp;when we receive references from business partners and third parties, for example, where you have been referred by them;</li>
                    <li className="privacy-list">&nbsp;when your images are captured by us via CCTV cameras while you are within our premises, or via photographs or videos taken by us or our representatives when you attend events hosted by us;</li>
                    <li className="privacy-list">&nbsp;when we seek or receive information about you in connection with your policy contract, policycontract applications, claims/ service requests or job applications, for example, from insurers, insurance associations, hospitals, clinics, motor workshops, your ex-employer and the relevant authorities; and/or</li>
                    <li className="privacy-list">&nbsp;when you submit your Personal Data to us for any other reason.</li>
                </ol>
                <p>2.2&nbsp; When you browse our website, you generally do so anonymously but please see the section below on cookies and related technologies. We do not at our website automatically collect Personal Data, including your email address unless you provide such information or login with your account credentials.</p>
                <p>2.3&nbsp; If you provide us with any Personal Data relating to a third party (e.g. information of your spouse, children, parents, and/or employees), including where you have named them as beneficiaries or as an insured/ policyholder/ contract holder, or where you refer a third party to us for the purposes of us offering our products and/or services to that third party, by submitting such information to us, you represent to us that you have obtained the consent of the third party to you providing us with their Personal Data for the respective purposes.</p>
                <p>2.4&nbsp; You should ensure that all Personal Data submitted to us is complete, accurate, true and correct. If there is a change to your personal particulars such as your residential/mailing address, email address or telephone number, please promptly update us. This ensures that you will continue to receive communications from us without disruption or delay. Failure on your part to do so may result in our inability to provide you with products and services you have requested.</p>
                <h4>3.Purposes for the Collection, Use and Disclosure of Your Personal Data</h4>
                <p>3.1&nbsp;&nbsp;<strong>Generally</strong>,&nbsp;Mavi collects, uses and discloses your Personal Data for the following purposes:</p>
                <ol className="privacy-order-list">
                    <li className="privacy-list">&nbsp;responding to, processing and handling your queries, complaints, feedback, suggestions and requests;</li>
                    <li className="privacy-list">&nbsp;verifying your identity;</li>
                    <li className="privacy-list">&nbsp;managing the administrative and business operations of the Companies and complying with internal policies and procedures;</li>
                    <li className="privacy-list">&nbsp;facilitating business asset transactions (which may extend to any merger, acquisition or asset sale) involving any of the Companies;</li>
                    <li className="privacy-list">&nbsp;matching any Personal Data held which relates to you for any of the purposes listed herein;</li>
                    <li className="privacy-list">&nbsp;requesting feedback or participation in surveys, as well as conducting market research and/or analysis using data analytics, artificial intelligence and machine learning for statistical, profiling, prediction or other purposes for us to design our products, understand individual behaviour, performance, preferences and market trends, perform internal assessment to improve business processes, and to review, develop and improve the quality of our products and services offered to you, and participating in industry studies for public interest and education in addition to the purposes outlined in the foregoing;</li>
                    <li className="privacy-list">&nbsp;preventing, detecting and investigating crime, including detecting anomalies in payment systems to prevent fraud and money-laundering or terrorist financing, and analysing and managing commercial risks;</li>
                    <li className="privacy-list">&nbsp;managing the safety and security of our premises and services (including but not limited to carrying out CCTV surveillance and conducting security clearances);</li>
                    <li className="privacy-list">&nbsp;project management;</li>
                    <li className="privacy-list">&nbsp;providing media announcements and responses;</li>
                    <li className="privacy-list">&nbsp;organising promotional events;</li>
                    <li className="privacy-list">&nbsp;in connection with any claims, actions or proceedings (including but not limited to drafting and reviewing documents, transaction documentation, obtaining legal advice, and facilitating dispute resolution), and/or protecting and enforcing our contractual and legal rights and obligations;</li>
                    <li className="privacy-list">&nbsp;managing and preparing reports on incidents and accidents;</li>
                    <li className="privacy-list">&nbsp;complying with any applicable rules, laws and regulations, codes of practice or guidelines or to assist in law enforcement and investigations by relevant authorities (including but not limited to disclosures to regulatory bodies, conducting audit checks, surveillance and investigation or conducting customer due diligence); and/or any other purposes relating to or reasonably necessary for any of the aforesaid.</li>
                </ol>
                <p>These purposes may also apply even if you do not maintain any policy/ contract or relationship, or have terminated the policy/ contract or relationship.</p>
                <p>3.2&nbsp;&nbsp;<strong>In addition,</strong>&nbsp;Mavi collects, uses and discloses your Personal Data for the following purposes depending on the nature of our relationship:</p>
                <ol className="privacy-order-list">
                    <li className="privacy-list"><strong>If you are a prospective customer or policy holder/ contracting party</strong></li>
                </ol>
                <ol>
                    <ol className="privacy-order-list">
                        <li className="privacy-list">providing services, including evaluating your needs and contacting you (including by way of telephone calls) to provide recommendations to you as to the type of products and services suited to your needs;</li>
                        <li className="privacy-list">where account or relationship managers or agents have been assigned to service your account or portfolio, we may also use your telephone number(s) to contact you from time to time in order to take your instructions, and/or provide you with information, updates, or recommendations and/or in accordance with the terms and conditions of our agreement with you;</li>
                        <li className="privacy-list">arranging contracts of insurance and other types of contract;</li>
                        <li className="privacy-list">assessing and processing any applications or requests made by you for products and services offered by Mavi;</li>
                        <li className="privacy-list">facilitating the continuation or termination of the insurance/ contractual relationship or the daily operation of the services and providing policy holder servicing (including but not limited to responding to your individual requests, and providing customer satisfaction);</li>
                        <li className="privacy-list">administering exceptional requests, including approvals or waivers;</li>
                        <li className="privacy-list">carrying out policyholder/ contractual reviews;</li>
                        <li className="privacy-list">assessing the relevant risk factors and determining the premium levels payable;</li>
                        <li className="privacy-list">providing offers and promotions (including updates and upgrades) you are entitled to receive in relation to Mavi or co-branded products and services which you have applied for;</li>
                        <li className="privacy-list">customer outreach and relationship management;</li>
                        <li className="privacy-list">archival management (including but not limited to warehouse storage and retrievals); communicating with you (including by way of telephone calls) in relation to your policy/ contract and informing you of updates to the policy/ contract benefits, terms and conditions and providing administrative information, including for the purposes of servicing you in relation to the products and services offered to you;</li>
                        <li className="privacy-list">administering, maintaining, managing and operating the products and services offered to you, including interface with reinsurers;</li>
                        <li className="privacy-list">sending updates and administrative notices to you in relation to the products and services used by you, including notification of maturity of payments and maturity of policies;</li>
                        <li className="privacy-list">facilitating performance and reviewing claims/ service requests history;</li>
                        <li className="privacy-list">collecting premiums/ contract service fees and debt collection;</li>
                        <li className="privacy-list">assessing and processing of any claims/ service requests and payments arising under your policy/ contract including by requesting medical reports; and/or</li>
                        <li className="privacy-list">any other purposes relating to or reasonably necessary for any of the aforesaid.<br /> </li>
                    </ol>
                </ol>
                <p><strong>3. If you submit an application to us as a candidate for an employment or representative position</strong></p>
                <ol>
                    <li className="privacy-list">processing your application including pre-recruitment checks, such as in relation to your credit standing and qualifications;</li>
                    <li className="privacy-list">providing or obtaining employee references and for background screening/vetting;</li>
                    <li className="privacy-list">facilitating and conducting interviews;</li>
                    <li className="privacy-list">assessing your suitability for the position applied for;</li>
                    <li className="privacy-list">communicating with you as required by Mavi to comply with its policies and processes, including for business continuity purposes;</li>
                    <li className="privacy-list">processing staff referrals; and/or</li>
                    <li className="privacy-list">any other purposes relating to or reasonably necessary for any of the aforesaid.<br /> </li>
                </ol>
                <p>You should ensure that all Personal Data submitted to us is complete, accurate, true and correct. Failure on your part to do so may result in delays in the processing of your application, or our inability to process your application.</p>
                <h4>4.  Disclosure of Personal Data</h4>
                <p>4.1  Mavi will take reasonable steps to protect your Personal Data against unauthorised disclosure. Subject to the provisions of any applicable law, your Personal Data may be provided to and processed by the following entities or parties, for the purposes listed above (where applicable), whether such entities or parties are located in Singapore, India or in another country:</p>
                <ol>
                    <li className="privacy-list">Mavi’s related Companies;</li>
                    <li className="privacy-list">insurers and reinsurers;</li>
                    <li className="privacy-list">where relevant, surveyors, loss adjusters, private investigators, motor workshops, hospitals or clinics in connection with policies/ contracts or claims/ service requests;</li>
                    <li className="privacy-list">associations to which Mavi Companies below including associations of insurance agents;</li>
                    <li className="privacy-list">agents, contractors or third party service providers who provide operational services to Mavi, such as courier services, telecommunications, information technology, payment, payroll, processing, training, market research, storage, archival, customer support investigation services;</li>
                    <li className="privacy-list">vendors or other third party service providers in connection with products and services offered by Mavi;</li>
                    <li className="privacy-list">credit reporting agencies, including Credit Bureau (Singapore), or in the event of default or disputes, any debt collection agencies or dispute resolution centres such as the Financial Industry Disputes Resolution Centre Ltd;</li>
                    <li className="privacy-list">your family, relatives, appointed executors and their professional advisers in connection with your will(s) or the administration of your estate;</li>
                    <li className="privacy-list">any business partner, investor, assignee or transferee (actual or prospective) to facilitate business asset transactions (which may extend to any merger, acquisition or asset sale) involving any of the Companies;</li>
                    <li className="privacy-list">where we require payment from you, your banks, credit card companies and their respective service providers;</li>
                    <li className="privacy-list">third party referrers;</li>
                    <li className="privacy-list">our co-brand and other business partners;</li>
                    <li className="privacy-list">external business and charity partners in relation to corporate promotional events;</li>
                    <li className="privacy-list">our professional advisers such as our auditors and lawyers;</li>
                    <li className="privacy-list">relevant government regulators or authority or law enforcement agency to comply with any laws, rules and regulations or schemes imposed by any governmental authority; and/or</li>
                    <li className="privacy-list">any other party to whom you authorise us to disclose your Personal Data to.</li>
                </ol>
                <h4>5. Deemed Consent</h4>
                <p>5.1&nbsp; In addition to the matters set forth above, subject to and in accordance with applicable law, you shall be deemed to have consented to Mavi collecting, using, disclosing and sharing amongst themselves your Personal Data, and disclosing such Personal Data to the Companies' authorised service providers and relevant third parties:</p>
                <p><u>Deemed Consent by Conduct</u></p>
                <ol>
                    <li className="privacy-list">where in response to a request for your Personal Data in connection with identified purposes, you voluntarily provide such Personal Data to Mavi for such purpose(s) and it is reasonable that you would voluntarily provide such Personal Data;</li>
                </ol>
                <p><u>Deemed Consent by Contractual Necessity</u></p>
                <ol start="2">
                    <li className="privacy-list">where the collection, use or disclosure of your Personal Data is reasonably necessary for the conclusion and/or performance of a contract:</li>
                </ol>
                <ol>
                    <ol>
                        <li className="privacy-list">between you and Mavi, where you provide Personal Data to Mavi with a view to entering into such contract with Mavi;</li>
                        <li className="privacy-list">between you and Mavi, where you provide Personal Data to Mavi pursuant to such contract with Mavi;</li>
                        <li className="privacy-list">between Mavi and any other organisation, where such contract is entered into at your request or pursuant to a contract between you and Mavi; and/or</li>
                        <li className="privacy-list">between Mavi and any other organisation, where a reasonable person would consider such conclusion or performance (as the case may be) to be in your interest.</li>
                    </ol>
                </ol>
                <p>For the avoidance of doubt, such other organisation(s) described under sub-paragraph 5.1.2 points 2.3 and 2.4 above may include recipients of your Personal Data not indicated in this Privacy Statement;</p>
                <p><u>Deemed Consent by Notification</u></p>
                <ol start="3">
                    <li className="privacy-list">
                        in the manner and for any of the purposes set forth in this Privacy Statement, as well as any other purpose(s) as we may from time to time notify you, where:
                        <ol>
                            <li className="privacy-list">we have taken reasonable steps to bring to your attention, which you agree includes posting on our website or communicating with you via any contact email or address we may have on record, SMS, mobile applications or our portal:</li>

                            <ol>
                                <li className="privacy-list">Mavi’s intention to collect, use and/or disclose the Personal Data (as the case may be) for such purpose(s),&nbsp;<strong>which may also include indicating such purpose(s) in this Privacy Statement from time to time;</strong>&nbsp;and</li>
                                <li className="privacy-list">a reasonable period within which, and a reasonable manner by which, you may notify Mavi that you do not consent to the proposed collection, use and/or disclosure of Personal Data; and</li>
                            </ol>

                        </ol>
                        <ol start="2">
                            <li className="privacy-list">you do not notify Mavi before the expiry of the period described in sub-paragraph 5.1.3 point 3.1.2 above that you do not consent to the proposed collection, use and/or disclosure of your Personal Data for such purpose(s).&nbsp;<strong>For the avoidance of doubt, you shall be deemed to be notified of all the purposes set forth in this Privacy Statement on the date of each instance of your perusal or loading of this document.</strong></li>
                        </ol>

                    </li>
                </ol>




                <p>6.1&nbsp; In addition to and without limiting the consents you have provided to our collection, use and disclosure of your Personal Data for the purposes set out elsewhere in this Privacy Statement, where permitted by applicable law, Mavi may also in accordance the requirements thereof also collect, use and/or disclose your Personal Data as further detailed below including without consent, where we meet the requirements of applicable law:</p>
                <p><u>Legitimate Interests</u></p>
                <ol>
                    <li className="privacy-list">we may collect, use and/or disclose your Personal Data without your consent for the purposes which we may notify you from time to time, where in the legitimate interests of Mavi or another person, including without limitation the purposes as notified to you above in paragraphs 3.1, 3.2 and 5.1.2 and 6.1.2 of this Privacy Statement, but not for sending you direct marketing messages unless you have otherwise provided your consent; and</li>
                </ol>
                <p><u>Business Improvement Purposes</u></p>
                <ol start="2">
                    <li className="privacy-list">we may collect your Personal Data from any of the Companies, we may use your Personal Data, and any of the Companies may disclose your Personal Data to us, for the following purposes (regardless whether you are an existing or prospective customer of any of the Companies):</li>
                </ol>
                <ol>
                    <ol>
                        <li className="privacy-list">improving or enhancing any goods or services provided, or developing new goods or services to be provided, by any of the Companies;</li>
                        <li className="privacy-list">improving or enhancing the methods or processes, or developing new methods or processes, for the operations of any of the Companies;</li>
                        <li className="privacy-list">learning about and understanding the behaviour and preferences of any customer of any of the Companies or another individual in relation to the goods or services provided by any of the Companies; and/or</li>
                        <li className="privacy-list">identifying any goods or services provided by any of the Companies that may be suitable for any customer of any of the Companies or another individual, or personalising or customising any such goods or services for you or another individual.</li>
                    </ol>
                </ol>




                <h4>7. Use of Cookies and Related Technologies</h4>

                <p>7.1&nbsp; Our websites and platforms use cookies and other technologies. Cookies are small text files stored in your computing or other electronic devices when you visit our website and platforms for record keeping purposes. Cookies are stored in your browser’s file directory, and the next time you visit the website or platform, your browser will read the cookie and relay the information back to the website, platform or element that originally set the cookie. Depending on the type of cookie it is, cookies may store user preferences and other information.</p>
                <p>7.2&nbsp; Web beacons (also known as pixel tags and clear GIFs) involve graphics that are not apparent to the user. Tracking links and/or similar technologies consist of a few lines of programming code and can be embedded in our websites or platforms. Web beacons are usually used in conjunction with cookies and primarily used for statistical analysis purposes. This technology can also be used for tracking traffic patterns on websites and platforms, as well as finding out if an e-mail has been received and opened and to see if there has been any response.</p>
                <p>7.3&nbsp; We may employ cookies and other technologies as follows:</p>
                <ol>
                    <li className="privacy-list">tracking information such as the number of visitors and their frequency of use, profiles of visitors and their preferred sites;</li>
                    <li className="privacy-list">making our websites and platforms easier to use. For example, cookies may be used to help speed up your future interactions with our websites and platforms;</li>
                    <li className="privacy-list">to better tailor our products and services to your interests and needs. For example, cookies information may be identified and disclosed to our vendors and business partners to generate consumer insights;</li>
                    <li className="privacy-list">collating information on a user’s search and browsing history;</li>
                    <li className="privacy-list">when you interact with us on our websites and platforms, we may automatically receive and record information on our server logs from your browser. We may collect for the purposes of analysis, statistical and site-related information including, without limitation, information relating to how a visitor arrived at the website or platform, the browser used by a visitor, the operating system a visitor is using, a visitor's IP address, and a visitor's click stream information and time stamp (which may include for example, information about which pages they have viewed, the time the pages were accessed and the time spent per web page);</li>
                    <li className="privacy-list">using such information to understand how people use our websites and platforms, and to help us improve their structure and contents;</li>
                    <li className="privacy-list">using cookies that are necessary in order to enable our websites and platforms to operate, for example, cookies that enable you to log onto secure parts of our websites and platforms; and/or</li>
                    <li className="privacy-list">personalising the website and platform for you, including delivering advertisements which may be of particular interest to you and using cookie related information to allow us to understand the effectiveness of our advertisements.</li>
                </ol>
                <p>7.4&nbsp; Some cookies we use are from third party companies to provide us with web analytics and intelligence about our websites and platforms. These companies collect information about your interaction with our websites and platforms. We use such information to compile statistics about visitors who interact with the websites, platforms and other Mavi online content, to gauge the effectiveness of our communications, and to provide more pertinent information to our visitors.</p>
                <p>7.5&nbsp; If you do not agree to such use of cookies, you can adjust your browser settings. Unless you have adjusted your browser settings to block cookies, our system will issue cookies as soon as you visit our site or click on a link in a targeted email that we have sent you, even if you have previously deleted our cookies.</p>
                <p>7.6&nbsp; The way which cookies can be managed depends on your browser. For more information on how to configure or disable cookies, please refer to the 'Help' option of your internet browser.</p>
                <p>7.7&nbsp; If you do not agree to our use of cookies and other technologies as set out in this Privacy Statement, you should delete or disable the cookies associated with our websites and platforms by changing the settings on your browser accordingly. However, you may not be able to enter certain part(s) of our websites or platforms. This may also impact your user experience while on our websites or platforms.</p>
                <p>7.8&nbsp; Data analytics, artificial intelligence and machine learning may be used for some of the purposes stated in paragraph 3 to 6.</p>


                <h4>8. Data Security</h4>
                <p>8.1&nbsp; Mavi will take reasonable efforts to protect Personal Data in our possession or our control by making reasonable security arrangements to prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks. However, we cannot completely guarantee the security of any Personal Data we may have collected from or about you, or that for example no harmful code will enter our website (for example viruses, bugs, trojan horses, spyware or adware). You should be aware of the risks associated with using websites.</p>
                <p>8.2&nbsp; While we strive to protect your Personal Data, we cannot ensure the security of the information you transmit to us via the Internet, and we urge you to take every precaution to protect your Personal Data when you are on the Internet. We recommend that you change your passwords often, use a combination of letters and numbers, and ensure that you use a secure browser.</p>
                <p>8.3&nbsp; If applicable, you undertake to keep your username and password secure and confidential and shall not disclose or permit it to be disclosed to any unauthorised person, and to inform us as soon as reasonably practicable if you know or suspect that someone else knows your username and password or believe the confidentiality of your username and password has been lost, stolen or compromised in any way or that actual or possible unauthorised transactions have taken place. We are not liable for any damages resulting from any security breaches related to unauthorised and/or fraudulent use of your username and password.</p>

                <h4>9. Anti-Spam</h4>
                <p>9.1&nbsp; If you receive spam messages and want to report it to us, please forward the message you have received to&nbsp;ITsupport@mavi-holding.com.</p>

                <h4>10. Third-Party Sites</h4>
                <p>10.1  Our website may contain links to other websites operated by third parties. We are not responsible for the privacy practices of websites operated by third parties that are linked to our website. You should review the privacy policies of such third party websites. Some of these third party websites may be co-branded with our logo or trademark, even though they are not operated or maintained by us. Once you have left our website, you should check the applicable privacy policy of the third party website to determine how they will handle any information they collect from you.</p>

                <h4><strong>11. Contacting Us – Questions, Feedback, Withdrawal of Consent, Access and Correction of your Personal Data</strong></h4>

                <p>11.1&nbsp; If you:</p>
                <ol>
                    <li className="privacy-list">have any questions or feedback relating to your Personal Data or our Privacy Statement, including without limitation our reliance on legitimate interests and/or deemed consent bases of collecting, using and/or disclosing your Personal Data as described in paragraph 6 of this Privacy Statement;</li>
                    <li className="privacy-list">would like to withdraw your consent to any use of your Personal Data as set out in this Privacy Statement; or</li>
                    <li className="privacy-list">would like to obtain access and make corrections to your Personal Data records,</li>
                </ol>

                <p>please contact Mavi as follows:</p>
                <p><strong>Email:&nbsp;ITsupport@mavi-holding.com</strong><br /> </p>

                <p>11.4&nbsp; However, if you withdraw your consent for us to use your Personal Data for any purposes which we regard as necessary to provide you products and services supplied by or through us (such as for example, matters relating to the servicing and administration of your insurance policy/ contract), this may affect our ability to provide you with such products and services (including preventing us from maintaining in force your insurance cover or properly assessing and processing your claims/ service requests), or performing or concluding an existing or prospective agreement. Withdrawing such consent may also result in the termination of all your policies and contractual relationships with us. This may also be disadvantageous to you, as you may lose valuable benefits from the policy/ contract and/or it may not be possible for you to obtain a similar level of protection on the same terms in the future. There may be other consequences of your withdrawal which Mavi may advise you of. In the event of any withdrawal of your consents in relation to your Personal Data, Mavi's legal rights and remedies are expressly reserved.</p>

                <h4>12. Governing Law</h4>

                <p>12.1  This Privacy Statement and your use of this website shall be governed in all respects by the laws of Singapore or India – depending on your location.</p>


            </div>
              </div>
            </div>
          </div>
        </div>
      </section>


            


        </>
    );
}

export default PrivacyPolicy;
