import React, { useState } from 'react';
import { imageURL } from 'components/URLs/URL';
import { 
    CarouselControl, 
    Carousel, 
    CarouselItem, 
    CarouselIndicators, 
} from 'reactstrap'; 

function HomeCarousel({ banners }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const itemLength = banners.length; // Total number of banners

  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const carouselItemData = banners.map((banner, index) => {
    const bannerImage = banner; // Assuming banner is the image file name
    console.log(bannerImage, 'shubham1');
    console.log(`${imageURL}/${bannerImage}`, "line no. 42");

    return (
      <CarouselItem
        key={`${index}_banner`}
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
      >
        <img
          className='bannerHeight'
          src={`${imageURL}/${bannerImage}`} // Use imageURL and the banner's image file name
          width="100%"
          style={{ height: "45rem", objectFit: "cover" }}
          alt={`Banner ${index}`} 
        />
      </CarouselItem>
    );
  });
  
  return (
    <div className='divBannerHeight' style={{ display: 'block', width: "100%", height: "45rem" }}>
      <Carousel previous={previousButton} next={nextButton} activeIndex={activeIndex}>
        <CarouselIndicators 
          items={banners} 
          activeIndex={activeIndex} 
          onClickHandler={(newIndex) => { if (animating) return; setActiveIndex(newIndex); }} 
        />
        {carouselItemData}
        <CarouselControl directionText="Prev" direction="prev" onClickHandler={previousButton} />
        <CarouselControl directionText="Next" direction="next" onClickHandler={nextButton} />
      </Carousel>
    </div>
  );
}

export default HomeCarousel;
