import React, { useState, useEffect } from "react";
import HomeCarousel from "components/HomeComponents/HomeCarousel";
import HomeRepairServices from "components/HomeComponents/HomeRepairServices";
import GetInTouch from "components/HomeComponents/GetInTouch";
import FetchApiMethod from "Helpers/FetchApiMethod";

const Home = () => {
  const [banners, setBanners] = useState([]);
  const [events, setEvents] = useState([]);
  const [services, setServices] = useState([]); // State for service data


  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsData = await FetchApiMethod("gethomepage", { language: "en" }, "POST");

        // Extract banner images from the 'details' array in the API response
        const bannerImages = eventsData?.data?.details?.map(detail => detail.images) || [];
        console.log(bannerImages, 'shubham');

        setEvents(eventsData?.data?.details || []); // Ensure 'details' exist in events
        setBanners(bannerImages); // Set the banners array

        const servicesData = await FetchApiMethod("getAllServiceType", {status: "Active"}, "POST");
        setServices(servicesData?.data || []); // Set the fetched service data
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <HomeCarousel banners={banners} />
      <HomeRepairServices eventsData={events} servicesData={services} /> {/* Pass services to HomeRepairServices */}
      <GetInTouch />
    </>
  );
};

export default Home;
