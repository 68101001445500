import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";  // Import useLocation to get URL params
import CategoryBanners from "components/CategoryComponents/CategoryBanners";
import AllServiceCategory from "components/CategoryComponents/AllServiceCategory";
import GetInTouch from "components/HomeComponents/GetInTouch";
import FetchApiMethod from "Helpers/FetchApiMethod";

// Helper function to get query parameters from the URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AllCategory = () => {
  const [banners, setBanners] = useState([]);
  const [services, setServices] = useState({ service_categories: [], service_type: {} });
  
  const query = useQuery();  // Initialize query to get parameters
  const serviceToken = query.get('service_token') || '2'; // Default to '2' if service_token is not provided
  const serviceTypeId = query.get('service_type_id') || '1'; // Default to '1' if service_type_id is not provided

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use the serviceToken and serviceTypeId from the URL in the API call
        const eventsData = await FetchApiMethod(
          "getServiceTypeWithCategories", 
          { language: "en", service_type_id: serviceTypeId, status: "Active" }, // Pass serviceTypeId here
          "POST"
        );

        const bannerImage = eventsData?.data?.service_type?.banner_image || [];
        const text1 = eventsData?.data?.service_type?.text1 || "Default Text"; // Get text1 from API
        const text2 = eventsData?.data?.service_type?.text2 || "Default1 Text"; // Get text2 from API

        console.log(bannerImage, 'Updated bannerImage log');

        setBanners([bannerImage]); 

        // Set services state to include both service_categories and service_type
        setServices({
          service_categories: eventsData?.data?.service_categories || [],
          service_type: eventsData?.data?.service_type || {}
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [serviceToken, serviceTypeId]); // Trigger effect whenever serviceToken or serviceTypeId changes

  return (
    <>
      {/* Pass banners and text1, text2 to the CategoryBanners component */}
      <CategoryBanners 
        banners={banners} 
        text1={services.service_type.text1} 
        text2={services.service_type.text2} 
      />
      <AllServiceCategory servicesData={services} />
      <GetInTouch />
    </>
  );
};

export default AllCategory;
