import React, { useState, useRef } from "react";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import ButtonIcon from "views/IndexCompnents/ButtonsSection/ButtonIcon";
import { NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";
import LoginHeader from "components/Navbars/LoginHeader";
import ReCAPTCHA from "react-google-recaptcha";
import Carousel from 'react-bootstrap/Carousel';
import Slider from "assets/img/theme/LoginRegisterslider.png";
import Slider2 from "assets/img/theme/LoginRegisterslider2.png";
import Slider3 from "assets/img/theme/LoginRegisterslider3.png";
import Slider4 from "assets/img/theme/LoginRegisterslider4.png";
import SignInFirst from "assets/img/theme/SignInFirst.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { MdKey } from "react-icons/md";
import { PiUserCircleFill } from "react-icons/pi";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa6";
import { apiURL1 } from "../src/components/URLs/URL";



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faUser, faEnvelope, faPhone, faKey, faEye, faEyeSlash, faGlobe } from '@fortawesome/free-solid-svg-icons';


// import { User, Mail, Phone, Lock, Globe, Eye, EyeOff } from 'lucide-react';


const Login = () => {
  const [recaptchaToken, setRecaptchaToken] = useState(""); // For storing reCAPTCHA token
  const [isSignUp, setIsSignUp] = useState(false); // State to toggle between sign in and sign up
  const [agree, setAgree] = useState(false); // State for terms and conditions checkbox
  const [selectedLanguage, setSelectedLanguage] = useState("english"); // Default language
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Toggle for forgot password form
  const [isVerifyEmail, setIsVerifyEmail] = useState(false); // New state to toggle between forgot password and verify email
  const [email, setEmail] = useState(""); // Store email for forgot password and login
  const [password, setPassword] = useState(""); // Store password for login
  // const [otp, setOtp] = useState(""); // Store OTP for verification
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [messageColor, setMessageColor] = useState("black");
  const [fullName, setFullName] = useState(""); // Store full name for sign up
  const [username, setUsername] = useState(""); // Store username for sign up
  const [phoneNo, setPhoneNo] = useState(""); // Store phone number for sign up
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState(""); // For error/success messages

  const [resendEnabled, setResendEnabled] = useState(false); // Control resend button
  const [timeLeft, setTimeLeft] = useState(60); // Countdown timer for resend

  const navigate = useNavigate(); // Use navigate instead of router.push

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token); // Update reCAPTCHA token
  };
  

  const handleCheckboxChange = (e) => {
    setAgree(e.target.checked); // Update checkbox state
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value); // Update language selection
  };

  // For Carousel
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClose = () => {
    navigate("/"); // Example: Navigate to home page when closed
  };
  

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [matchError, setMatchError] = useState(false);

  // Validate password match
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setMatchError(e.target.value !== confirmPassword); // Check if password and confirm password match
  };
  

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setMatchError(password !== e.target.value); // Check if password and confirm password match
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  // const togglePasswordVisibility1 = () => {
  //   setShowPassword1(!showPassword1);
  // };



  // const togglePasswordVisibility1 = () => {
  //   setShowPassword1((prevState) => !prevState);
  // };
  


// const handlePhoneNumberChange = (e) => {
//   const value = e.target.value;
//   const phoneNumberPattern = /^\d{10}$/; // Example: 10-digit number
//   const numericValue = value.replace(/\D/g, ''); // Remove non-digit characters

//   if (numericValue.length <= 10) { // Ensure the length does not exceed 10
//     setPhoneNo(numericValue);
//     if (phoneNumberPattern.test(numericValue)) {
//       setMessage(""); // Clear message if valid
//     } else {
//       setMessage("Please enter a valid 10-digit phone number");
//     }
//   }
// };


const handleEmailChange = (e) => {
  setEmail(e.target.value);
  setIsOtpSent(false); // Reset OTP sent status if email is changed
};

const handleSendOtp = async () => {
  try {
    const response = await fetch(`${apiURL1}sendOTP`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, type: "V" }),
    });
    const data = await response.json();
    if (data.status) {
      setIsOtpSent(true);
      setMessage("OTP sent successfully.");
      setMessageColor("green");
      setResendEnabled(false);
      setTimeLeft(60);
    } else {
      setMessage(data.message || "Failed to send OTP.");
      setMessageColor("red");
    }
  } catch (error) {
    console.error("Error sending OTP:", error);
    setMessage("An error occurred, please try again.");
    setMessageColor("red");
  }
};

// const handleOtpChange = (index, value) => {
//   const newOtp = [...otp];
//   newOtp[index] = value.slice(-1); // Only keep the last digit entered
//   setOtp(newOtp);
// };

const handleOtpVerification = async (e) => {
  e.preventDefault();
  const otpCode = otp.join(""); // Join OTP array into a single string

  try {
    const response = await fetch(`${apiURL1}verify-OTP`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, otp: otpCode }),
    });
    const data = await response.json();
    if (data.status) {
      setIsEmailVerified(true);
      setMessage("OTP verified! Continue with your registration.");
      setMessageColor("green");
    } else {
      setMessage(data.message || "Invalid or expired OTP.");
      setMessageColor("red");
    }
  } catch (error) {
    console.error("Error verifying OTP:", error);
    setMessage("An error occurred, please try again.");
    setMessageColor("red");
  }
};






const handlePhoneNumberChange = (e) => {
  const value = e.target.value;
  const phoneNumberPattern = /^\d{10}$/; // Example: 10-digit number
  const numericValue = value.replace(/\D/g, ''); // Remove non-digit characters

  if (numericValue.length <= 10) { // Ensure the length does not exceed 10
    setPhoneNo(numericValue);
    if (phoneNumberPattern.test(numericValue)) {
      setMessage(""); // Clear message if valid
    } else {
      setMessage("Please enter a valid 10-digit phone number");
    }
  }
};
  


  // Handle form submission for login or sign up
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      setMessage("Please verify that you're not a robot.");
      return;
    }

     if (isSignUp && password !== confirmPassword) {
        setMessage("Error: Passwords do not match!");
        return;
      }
    

    if (isSignUp && !agree) {
      setMessage("Please agree to the Terms and Conditions & Privacy Policy.");
      return;
    }

      if (isSignUp && !isEmailVerified) {
        // Show error if email is not verified
        setMessage("First verify your email");
        return;
      }

    // Different API calls based on the form type
    try {
      let response;


      // if (isSignUp) {
      //   response = await FetchApiMethod("createuser", {
      //     name: fullName,
      //     email,
      //     phone_no: phoneNo,
      //     password,
      //     language: selectedLanguage === "english" ? "en" : "ch",
      //     user_type_id: 3,
      //   }, "POST");
      // } else if (isForgotPassword) {
      //   response = await FetchApiMethod("forgetpassword", { email }, "POST");
      //   setIsVerifyEmail(true);
      // } else if (isVerifyEmail) {
      //   response = await FetchApiMethod("verifyotp", { reset_token: otp }, "POST");
      // } else {
      //   response = await FetchApiMethod("login", { email, password }, "POST");
      // }


      if (isSignUp) {
        response = await fetch(`${apiURL1}createuser`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: fullName,
            email,
            phone_no: phoneNo,
            password,
            language: selectedLanguage === "english" ? "en" : "ch",
            user_type_id: 3,
            terms_condition: true
          }),
        });
      } else if (isForgotPassword) {
        response = await fetch(`${apiURL1}forgetpassword`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });
        setIsVerifyEmail(true);
      } else if (isVerifyEmail) {
        response = await fetch(`${apiURL1}verifyotp`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            reset_token: otp,
          }),
        });
      } else {
        response = await fetch(`${apiURL1}login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        });
      }

      const data = await response.json();
      if (response.ok) {
        console.log('----', data.data.id, '------');
        localStorage.setItem('user_type_id', JSON.stringify(data.data.user_type_id));
        localStorage.setItem('useremail', JSON.stringify(data.data.email));
        localStorage.setItem('new_user_id', JSON.stringify(data.data.id));
        localStorage.setItem('user', JSON.stringify(data.data));
        localStorage.setItem('loginFullName', JSON.stringify(data.data.name));
        localStorage.setItem('loginUserPhone', JSON.stringify(data.data.phone_no));
        setMessage("Success!");
        const finaluserdata = localStorage.getItem('data')
        if (!finaluserdata) {
          navigate("/login", { state: { showSignIn: true } });
        }
        // Redirect to login after a successful sign-up
        if (isSignUp) {
          setTimeout(() => {
            navigate("/login", { state: { showSignIn: true } });
          }, 1000); // Delay for showing success message
        }

        if (!isSignUp && !isForgotPassword && !isVerifyEmail) {
          navigate("/AllCategory");
          // setIsLoading(true); // Show loader before navigating
          window.location.reload(); // Refresh the page
        }
      } else {
        setMessage(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error("API Error:", error);
      setMessage("An error occurred, please try again.");
    }
  };


  // useEffect(() => {
  //   // Check if we need to show the Sign In form (from successful sign-up)
  //   if (location.state?.showSignIn) {
  //     setIsSignUp(false); // Force it to show Sign In form
  //   }
  // }, [location.state]);

  useEffect(() => {
    // Check if we need to show the Sign In or Sign Up form (from successful sign-up or sign-up click)
    if (location.state?.showSignIn) {
      setIsSignUp(false); // Force it to show Sign In form
    } else if (location.state?.showSignUp) {
      setIsSignUp(true); // Force it to show Sign Up form
    }
  }, [location.state]);

/* otp countinue input and back code modified by om */
  const otpInputs = useRef([]);

  // Handle OTP input change
  const handleOtpChange = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") { // Allow only numbers
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input automatically
      if (value !== "" && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  // Handle key down (for backspace support)
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  
  // Countdown logic using useEffect should be inside the component, not in a separate function
  useEffect(() => {
    let timer;
    if (!resendEnabled && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setResendEnabled(true); // Enable the resend button after 60 seconds
    }

    // Cleanup the interval to prevent memory leaks
    return () => clearInterval(timer);
  }, [resendEnabled, timeLeft]);

   // Handle resend OTP
   const resendOtp = () => {
    console.log("Resending OTP...");
    // Implement OTP resend logic here

    // Disable resend button and start countdown again
    setResendEnabled(false);
    setTimeLeft(60); // Reset the countdown
  };


  return (
    <>
      <style>{`
        body, html {
          margin: 0;
          padding: 0;
          font-family: Arial, sans-serif;
          height: 100%;
        }
        #root {
          height: 100%;
        }
        .app {
          display: flex;
          height: 90vh;
          background-image: url(${SignInFirst}); 
          background-size: cover;
          background-repeat: no-repeat;
        }
        .content {
          display: flex;
          width: 100%;
          height: 93%;
          max-width: 1200px;
          margin: auto;
          overflow: hidden;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        }
        .image-section {
          flex: 0;
          background-color: #f0f8ff;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0rem;
        }
        .image-container img {
          width: 100%;
          height: auto;
        }
        .form-section {
          flex: 1;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          
          background-size: cover; /* Cover the entire section */
          background-position: center; /* Center the image */
        }
        .form-container {
          background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
          padding: 2rem;
          border-radius: 0px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        }
        .form-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: -2%
        }
        .form-title {
          font-size: 1.5rem;
          color: #1a2b5e;
        }
        .input-group {
          margin-bottom: 0.5rem;
        }
        .input-group input,
        .input-group select {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        .forgot-password, .signup-link {
          text-align: center;
          margin-top: 1rem;
        }
        .signup-link a {
          color: #3366cc;
          cursor: pointer;
        }
        /* Button styles */
        .forgot-password a, .signup-link span {
          color: #0056b3;
          cursor: pointer;
        }
        .close-btn{
          border: 0;
          background: transparent;
        }


        // .input-group {
        //   display: flex;
        //   align-items: center;
        // }

        .checkbox-label {
          display: flex;
          align-items: center;
          gap: 2px; /* Adjust the gap between the checkbox and text */
          font-size: 12px; /* Adjust as needed */
        }

        .checkbox-label input {
          width: 15px;
          height: 15px;
          margin-right: 10px; /* Adjust space between checkbox and label text */
        }

        .checkbox-label a {
          color: #0056b3; /* Customize link color */
          text-decoration: underline;
        }
          


        /* Media Queries for Tablet */
        @media (max-width: 768px) {
          .content {
            flex-direction: column;
          }
          .form-container {
            width: 80%;
          }
          .checkbox-label {
            display: flex;
            align-items: center;
            gap: 2px; /* Adjust the gap between the checkbox and text */
            font-size: 10px; /* Adjust as needed */
          }
          .checkbox-label input {
            width: 15px;
            height: 15px;
            margin-right: 5px; /* Adjust space between checkbox and label text */
          }
        }

        .carousel-image {
          // width: 100%;
          height: 700px; /* Adjust height as needed */
          object-fit: cover;
        }

        /* Optional: Ensure responsive behavior */
        @media (max-width: 768px) {
          .carousel-image {
            height: 300px; /* Adjust for smaller screens */
          }
        }


        /* Media Queries for Phone */
        @media (max-width: 576px) {
          .image-section {
            display: none; /* Hide image section on phones */
          }
          .form-section {
            padding: 1rem;
          }
          .form-container {
            width: 100%;
          }
          .form-title {
            font-size: 1.25rem;
          }
          .input-group input {
            padding: 0.5rem;
          }
          .forgot-password, .signup-link {
            text-align: left;
          }
        }
      `}</style>

      <LoginHeader />
      <div className="app">
        <div className="content mt-4 mb-2">
          {/* <div className="image-container">
            <img
              alt="scroll top"
              className="img-fluid"
              src={Slider}
            />
          </div> */}
          {/* Carousel Section */}
          
          {/* <div className="image-container"> */}
          {/* <div className="image-section">
            <Carousel>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block img-fluid w-100"
                  src={Slider}
                  alt="First slide"
                />
              </Carousel.Item>

              <Carousel.Item interval={1000}>
                <img
                  className="d-block img-fluid w-100"
                  src={Slider2}
                  alt="Second slide"
                />
              </Carousel.Item>

              <Carousel.Item interval={1000}>
                <img
                  className="d-block img-fluid w-100"
                  src={Slider3}
                  alt="Third slide"
                />
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block img-fluid w-100"
                  src={Slider4}
                  alt="Fourth slide"
                />
              </Carousel.Item>
            </Carousel>
          </div> */}


          <div className="image-section">
            <Carousel activeIndex={index} onSelect={handleSelect}>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block carousel-image"
                  src={Slider}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block carousel-image"
                  src={Slider2}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block carousel-image"
                  src={Slider3}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block carousel-image"
                  src={Slider4}
                  alt="Fourth slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>


          <div className="form-section form-container">
            <div className="">
              <div className="form-header justify-content-end mt-3">
              <button className="close-btn" style={{ fontSize: '30px', marginTop: '-18%' }} onClick={handleClose}>&times;</button>
              </div>
              <div className="form-header justify-content-center">
                <h2 className="form-title">
                  {isVerifyEmail
                    ? "Verify Email"
                    : isForgotPassword
                    ? "Forgot Password"
                    : isSignUp
                    ? "Sign Up"
                    : "Sign In"}
                </h2>
              </div>

              {/* Verify Email Form */}
                {isVerifyEmail ? (
                  <form onSubmit={handleSubmit}>
                    <p>
                      Please enter the 6 digit code sent to {email} to verify your email.
                    </p>
                    <div className="input-group d-flex justify-content-between">
                      {Array.from({ length: 6 }, (_, index) => (
                        <input key={index} type="text" maxLength="1" required style={{ width: '15%', textAlign: 'center' }} />
                      ))}
                    </div>

                    <p>
                      Resend OTP: <span>00:30</span>
                    </p>

                    <button type="submit" className="addNewBtnStyle btn-lg">
                      SUBMIT
                    </button>

                    <p className="signup-link text-left">
                      <span onClick={() => {
                        setIsVerifyEmail(false);  // Change to false to go back to Forgot Password form
                        setIsForgotPassword(true); // Set this to true so Forgot Password form is shown
                      }}>
                        Change Email
                      </span>
                    </p>
                  </form>
                ) : isForgotPassword ? (
                  <form onSubmit={() => setIsVerifyEmail(true)}> {/* Show verify email on "NEXT" */}
                    <div className="input-group">
                      <input 
                        type="email" 
                        placeholder="Enter Email id" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required 
                      />
                    </div>

                    <button type="submit" className="addNewBtnStyle btn-lg">
                      NEXT
                    </button>

                    <p className="signup-link text-left">
                      <span onClick={() => setIsForgotPassword(false)}>
                        Sign In
                      </span>
                    </p>
                  </form>
                ) : (
              <div style={{ maxHeight: "550px", overflowY: "auto", padding: "0px" }}>

                <form onSubmit={handleSubmit}>
                  {isSignUp ? (
                    <>
                      {/* Sign Up Form */}
                      {/* <div className="input-group">
                      <input 
                        className="text-capitalize" 
                        type="text" 
                        placeholder="Full name" 
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required 
                      />
                      </div> */}

                       <div className="input-group">
                          {/* <FontAwesomeIcon icon={faUser} className="icon icon-button p-1" /> */}
                          <PiUserCircleFill className="icon icon-button p-1" />
                          <input
                            className="input-field text-capitalize"
                            type="text"
                            placeholder="Full name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                          />
                        </div>

                      {/* <div className="input-group">
                      <input 
                        type="email" 
                        placeholder="Email id" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required 
                      />
                      </div> */}

                    <div className="input-group">
                    {/* <FontAwesomeIcon icon={faEnvelope} className="icon icon-button p-1" /> */}
                    <FaEnvelope className="icon icon-button p-1" />
                      <input
                        type="email"
                        className="input-field"
                        placeholder="Email id"
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                    </div>

                    {/* Verify Email Button */} 
                      {/* {!isEmailVerified && email && (
                        <button className="mb-1" onClick={handleSendOtp}
                        style={{ color: 'white', cursor: 'pointer',backgroundColor:'#4CAF50', borderRadius:'4px', border:'none', fontSize:'1'}}>
                          Verify Email
                        </button>
                      )} */}

                      {!isEmailVerified && email.includes('@') && (
                        <button
                          className="mb-1"
                          onClick={handleSendOtp}
                          style={{
                            color: 'white',
                            cursor: 'pointer',
                            backgroundColor: '#4CAF50',
                            borderRadius: '4px',
                            border: 'none',
                            fontSize: '1rem'
                          }}
                        >
                          Verify Email
                        </button>
                      )}

                      {/* OTP Input Fields */}
                      {isOtpSent && !isEmailVerified && (
                        <>
                          <p>Enter the OTP sent to {email}</p>
                          <div className="otp-group">
                            {otp.map((value, index) => (
                              <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={(input) => otpInputs.current[index] = input} // Use ref to control focus
                                required
                                style={{ width: "2em", marginRight: "0.5em", textAlign: "center" , fontSize:"1.2rem", borderRadius:'4px',border:'1px solid #444'}} // Center align input
                              />
                            ))}
                          </div>
                          <button 
                            className="mt-1 mb-1" 
                            onClick={handleOtpVerification} 
                            style={{ color: 'white', cursor: 'pointer',backgroundColor:'#4CAF50', borderRadius:'4px', border:'none', fontSize:'1' }}
                          >
                            Verify
                          </button>
                          <button 
                            className="mx-2 mt-1 mb-1" 
                            id="resendButton"
                            onClick={handleSendOtp}
                            disabled={!resendEnabled} // Disable button during countdown
                            style={{ 
                              color: 'white', 
                              cursor: resendEnabled ? 'pointer' : 'not-allowed', // Show pointer or not-allowed cursor
                              backgroundColor:'#4CAF50', 
                              borderRadius:'4px', 
                              border:'none', 
                              fontSize:'1' 
                            }}
                          >
                            {resendEnabled ? "Resend OTP" : `Resend in ${timeLeft}s`} {/* Show countdown */}
                          </button>
                        </>
                      )}


                      {/* <div className="input-group">
                        <input
                          type="tel"
                          placeholder="Enter contact number"
                          value={phoneNo}
                          onChange={(e) => setPhoneNo(e.target.value)}
                          minLength="2"
                          maxLength="10"
                          required
                        />
                      </div> */}
                      <div className="input-group">
                      {/* <FontAwesomeIcon icon={faPhone} className="icon icon-button p-1" /> */}
                      <FaPhoneAlt className="icon icon-button p-1" />

                        <input
                          className="input-field"
                          type="tel"
                          placeholder="+65  |  Enter contact number"
                          value={phoneNo}
                          onChange={handlePhoneNumberChange}
                          maxLength="10"
                          required
                          inputMode="numeric" // Suggest numeric keyboard
                        />
                      </div>

                      {/* <div className="input-group">
                      <FontAwesomeIcon icon={faKey} className="icon icon-button p-1" />
                        <input
                          className="input-field"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          placeholder="Create password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="input-group">
                      <FontAwesomeIcon icon={faKey} className="icon icon-button p-1" />
                        <input
                          className="input-field"
                          type="password"
                          placeholder="Confirm password"
                          required
                        />
                      </div> */}

                      {/* First password field */}
                      <div className="input-group">
                        <MdKey className="icon icon-button p-1" />
                        <input
                          className="input-field"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          placeholder="Create password"
                          onChange={handlePasswordChange}
                          required
                        />
                        <button onClick={togglePasswordVisibility} className="icon-button">
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                      </div>

                      {/* Second password field */}
                      <div className="input-group">
                        <MdKey className="icon icon-button p-1" />
                        <input
                          className="input-field"
                          type={showPassword2 ? "text" : "password"}
                          value={confirmPassword}
                          placeholder="Confirm password"
                          onChange={handleConfirmPasswordChange}
                          required
                        />
                        <button onClick={togglePasswordVisibility2} className="icon-button">
                          <FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} />
                        </button>
                      </div>

                      {/* Error message if passwords do not match */}
                      {matchError && <div className="error-message">Passwords do not match</div>}

                      <div className="input-group">
                      {/* <FontAwesomeIcon icon={faGlobe} className="icon icon-button p-1" /> */}
                      <FaGlobe className="icon icon-button p-1" />

                      <select
                        className="input-field"
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        required
                      >
                        <option value="en">English</option>
                        <option value="ch">Chinese</option>
                      </select>
                      </div>
                      <div className="input-group">
                        <label className="checkbox-label mt-1 p-1">
                          <input
                            type="checkbox"
                            checked={agree}
                            onChange={handleCheckboxChange}
                          />{" "}
                          I agree to the{" "}
                          <a href="/Terms-and-Conditions">Terms and Conditions</a> &{" "}
                          {/* <a href="/Feedback-Policy">Privacy Policy</a> of service. */}
                          <a href="/Privacy-Policy">Privacy Policy</a> of service.
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Sign In Form */}
                      <div className="input-group">
                      {/* <FontAwesomeIcon icon={faEnvelope} className="icon icon-button p-1" /> */}
                      <FaEnvelope className="icon icon-button p-1" />
                      <input 
                        className="input-field"
                        type="email" 
                        placeholder="Email id" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required 
                      />
                      </div>
                      {/* <div className="input-group">
                      <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <input
                        type={showPassword1 ? "text" : "password"}
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <button onClick={togglePasswordVisibility1}>
                        {showPassword1 ? "Hide" : "Show"} Password
                      </button>
                      </div>  */}

                      <div className="input-group">
                      {/* <FontAwesomeIcon icon={faKey} className="icon icon-button p-1" /> */}
                      <MdKey className="icon icon-button p-1" />
                        <input
                          type={showPassword1 ? "text" : "password"}
                          value={password}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          className="input-field"
                        />
                        <button onClick={togglePasswordVisibility1} className="icon-button">
                          <FontAwesomeIcon icon={showPassword1 ? faEyeSlash : faEye} />
                        </button>
                      </div>

                      <p className="forgot-password text-left">
                        <a onClick={() => setIsForgotPassword(true)}>
                          Forgot Password?
                        </a>
                      </p>
                    </>
                  )}

                  <ReCAPTCHA
                    sitekey="6Lf43WQqAAAAADiXHNl8nI_YK0Qwva__fHQeYiUH"
                    // sitekey="6Le7PWMqAAAAAK8ogFONPlIR3OPxIBR95SWa1qei" //For Local

                    onChange={handleRecaptchaChange}
                  />

                  {/* Display message */}
                  {message && <p className={message.includes('Error') ? "text-danger" : "text-success"}>{message}</p>}
                  {/* {message && <div className="alert alert-danger">{message}</div>} */}



                  <button type="submit" className="addNewBtnStyle btn-lg mt-2">
                    {isSignUp ? "Sign Up" : "Sign In"}
                  </button>

                  <p className="signup-link text-left">
                    {isSignUp ? (
                      <>
                        Already have an account?{" "}
                        <span onClick={() => setIsSignUp(false)}>Sign In</span>
                      </>
                    ) : (
                      <>
                        Don't have an account?{" "}
                        <span onClick={() => setIsSignUp(true)}>Sign Up</span>
                      </>
                    )}
                  </p>
                </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0 footer-background border-top border-light border-1 p-0">
        <Row className="align-items-center justify-content-center m-1">
          <Col lg="4" className="text-center">
            <p className="footer-credits text-white">© 2024 Mavi Care Services Singapore Pvt. Ltd. All rights reserved.</p>
          </Col>
        </Row>
      </div>

    </>
  );
};

export default Login;